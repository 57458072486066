@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("/assets/fonts/Poppins-Regular.ttf"), url("/assets/fonts/Poppins-Regular.woff"), url("/assets/fonts/Poppins-Regular.otf"), url("/assets/fonts/Poppins-Regular.eot");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("/assets/fonts/Poppins-Light.ttf"), url("/assets/fonts/Poppins-Light.woff"), url("/assets/fonts/Poppins-Light.otf"), url("/assets/fonts/Poppins-Light.eot");
}
@font-face {
  font-family: "Poppins-ExtraLight";
  src: url("/assets/fonts/Poppins-ExtraLight.ttf"), url("/assets/fonts/Poppins-ExtraLight.woff"), url("/assets/fonts/Poppins-ExtraLight.otf"), url("/assets/fonts/Poppins-ExtraLight.eot");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("/assets/fonts/Poppins-Medium.ttf"), url("/assets/fonts/Poppins-Medium.woff"), url("/assets/fonts/Poppins-Medium.otf"), url("/assets/fonts/Poppins-Medium.eot");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("/assets/fonts/Poppins-SemiBold.ttf"), url("/assets/fonts/Poppins-SemiBold.woff"), url("/assets/fonts/Poppins-SemiBold.otf"), url("/assets/fonts/Poppins-SemiBold.eot");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("/assets/fonts/Poppins-Bold.ttf"), url("/assets/fonts/Poppins-Bold.woff"), url("/assets/fonts/Poppins-Bold.otf"), url("/assets/fonts/Poppins-Bold.eot");
}
.container {
  max-width: 1170px !important;
}
@media (max-width: 991px) {
  .container {
    max-width: 720px !important;
  }
}

img {
  max-width: 100%;
}

h2 {
  font-family: "Poppins-Bold" !important;
}

h3 {
  font-family: "Poppins-SemiBold" !important;
}

h4 {
  font-family: "Poppins-Medium" !important;
}

body {
  font-family: "Poppins-Regular" !important;
  position: relative;
  padding-bottom: 84px;
}

img {
  max-width: 100%;
}

@media (max-width: 767px) {
  .container {
    max-width: 767px !important;
  }
}

.color_1 {
  color: #8e6a16;
}

.form-check .form-check-input {
  border: 1px solid #212529;
  margin-left: -35px !important;
}

.color_2 {
  color: #797979;
}

header {
  box-shadow: 0px 2px 9px #9a9a9a29;
  background-color: #ffffff;
  padding: 11px 0px 10px 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9999;
  transition: 0.7s;
  min-height: 86px;
}
header .logo-second {
  display: none;
}
header.header-clr-scnd {
  background-color: #003661;
}
header.header-clr-scnd .links ul li a {
  color: #fff;
}
header.header-clr-scnd .theme-btn .btn {
  background-color: #f0daac;
  color: #003661;
}
header.header-clr-scnd .login-out .user-sec .img {
  background-color: #fff;
}
header.header-clr-scnd .login-out .user-sec .img img {
  filter: inherit;
}
header.header-clr-scnd .logo-second {
  display: block;
}
header.header-clr-scnd .logo-first {
  display: none;
}
header.header-clr-scnd #toggle div {
  background: #fff;
}

.logo {
  max-width: 290px;
  margin-top: 2px;
}
@media (max-width: 574px) {
  .logo {
    width: 85%;
  }
}

.links {
  height: 100%;
  display: flex;
  width: 100%;
}
.links ul {
  margin: 0px 0 0 0;
  padding: 0;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .links ul {
    margin: 0px 0 0 0;
  }
}
@media (max-width: 991px) {
  .links ul {
    display: grid;
    flex-wrap: wrap;
    display: block;
  }
}
.links ul li {
  list-style-type: none;
}
@media (max-width: 991px) {
  .links ul li {
    display: block;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #8e6a16;
  }
}
.links ul li a {
  text-decoration: none;
  font-size: 14px;
  color: #8e6a16;
  font-family: "Poppins-Medium";
}
@media (max-width: 991px) {
  .links {
    height: 100%;
    overflow: auto;
  }
}

.top-menu-bar {
  min-height: 100%;
  align-items: center;
}

#toggle {
  width: 28px;
  margin: 0px 0 0 auto;
}
#toggle div {
  width: 100%;
  height: 5px;
  background: #44444f;
  margin: 4px auto;
  transition: all 0.3s;
  backface-visibility: hidden;
}

#toggle.on .one {
  transform: rotate(45deg) translate(5px, 5px);
}
#toggle.on .two {
  opacity: 0;
}
#toggle.on .three {
  transform: rotate(-45deg) translate(7px, -8px);
}

#menu {
  color: white;
  border: 1px solid #44444f;
  width: 150px;
  height: 100px;
  padding: 10px;
  border-radius: 3px;
  line-height: 100px;
  text-align: center;
  margin: auto;
  display: none;
}

.top-menu-bar {
  display: flex;
  align-items: center;
}
.top-menu-bar .toggle-top {
  margin: 6px 0px 0 20px;
  display: none;
}
@media (max-width: 574px) {
  .top-menu-bar .toggle-top {
    width: 15%;
  }
}

.service-table {
  overflow: auto;
}

.top-menu-bar .toggle-top {
  margin: 10px 0 0 20px;
  display: none;
}
@media (max-width: 991px) {
  .top-menu-bar .toggle-top {
    display: block;
    flex-grow: 1;
  }
}

.side-slide {
  transform: translate(0%) !important;
}

.header-scrolled {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991px) {
  .menu-links {
    position: fixed !important;
    left: 0;
    height: 100%;
    min-height: 100% !important;
    max-width: 320px !important;
    top: 86px;
    padding: 15px 15px;
    background-color: #ffffff;
    border-top: 1px solid #fff;
    box-shadow: 3px 6px 8px 1px #2a2a2a26;
    transition: 0.3s;
    transform: translate(-104%);
  }
}
@media (max-width: 574px) {
  .menu-links {
    top: 112px;
  }
}
@media (max-width: 375px) {
  .menu-links {
    top: 92px;
  }
}
@media (max-width: 320px) {
  .menu-links {
    top: 104px;
  }
}

.theme-btn.header-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.theme-btn .btn {
  background-color: #003661;
  border-radius: 0;
  padding: 6px 20px 8px 20px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 6px;
}
@media (max-width: 1439px) {
  .theme-btn .btn {
    padding: 6px 20px 8px 20px;
    font-size: 15px;
  }
}
@media (max-width: 574px) {
  .theme-btn .btn {
    min-width: 100%;
  }
}
.theme-btn .btn.second-color {
  background-color: #003661 !important;
}
.theme-btn .btn:hover {
  color: #fff;
}
.theme-btn.brown .btn {
  background-color: #8d6719;
}

.user-sec {
  display: flex;
  align-items: center;
}
.user-sec img {
  margin-right: 0px;
}

.login-out {
  position: relative;
}
.login-out .user-sec .img {
  height: 30px;
  min-height: 30px;
  width: 30px;
  min-width: 30px;
  background-color: #d2b271;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  margin-right: 10px;
}
.login-out .user-sec .img img {
  width: 20px;
  filter: brightness(0) invert(1);
}
.login-out ul {
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 9px #00000017;
  width: 111px;
  border-radius: 5px;
  padding: 7px 10px 8px 10px;
  left: -10px;
  display: none;
}
@media (max-width: 991px) {
  .login-out ul {
    position: relative;
    box-shadow: none;
    width: 100%;
    border-radius: 0px;
    padding: 8px 0px 8px 0px;
    left: 0;
  }
}
.login-out ul::before {
  content: "";
  position: absolute;
  top: -7px;
  right: 15px;
  height: 13px;
  width: 13px;
  background: #fff;
  transform: rotate(46deg);
  border: solid 1px rgba(156, 156, 156, 0.16);
  border-bottom: none;
  border-right: none;
}
.login-out ul li {
  padding: 2px 0;
  margin-bottom: 2px;
  display: block;
}
.login-out ul li a {
  display: block;
  color: #003661 !important;
}
.login-out:hover ul {
  display: block;
}

.banner-headings {
  margin-top: 60px;
}
.banner-headings.terms-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0 !important;
}
.banner-headings.terms-top h1 {
  margin-bottom: 0 !important;
}
.banner-headings.terms-top p {
  margin-top: 0 !important;
}
@media (max-width: 991px) {
  .banner-headings {
    margin-top: 30px;
  }
}
@media (max-width: 574px) {
  .banner-headings {
    margin-top: 0px;
  }
}
.banner-headings h1 {
  font-size: 49px;
  color: #fff;
  margin-bottom: 30px;
  font-family: "Poppins-SemiBold";
}
.banner-headings h1 .color_1 {
  margin-right: 8px;
}
.banner-headings h1.first {
  display: flex;
  white-space: nowrap;
  margin-bottom: 0px;
}
@media (max-width: 574px) {
  .banner-headings h1.first {
    display: block;
    white-space: inherit;
    margin-bottom: 0px;
  }
}
.banner-headings h1.first.second {
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .banner-headings h1 {
    font-size: 50px;
  }
}
@media (max-width: 574px) {
  .banner-headings h1 {
    font-size: 34px;
  }
}
@media (max-width: 375px) {
  .banner-headings h1 {
    font-size: 28px;
  }
}
.banner-headings p {
  font-size: 17px;
  color: #fff;
  margin-top: 30px;
}
.banner-headings p.first {
  display: flex;
  white-space: nowrap;
}
@media (max-width: 574px) {
  .banner-headings p.first {
    display: block;
    white-space: inherit;
  }
}
.banner-headings p a {
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  margin-left: 6px;
}

.banner {
  background-color: #d2b271;
  padding: 40px 0 0 0;
}
@media (max-width: 991px) {
  .banner {
    padding: 40px 0 40px 0;
  }
}
.banner .login-card {
  margin-bottom: 40px;
}

.login-card {
  margin-top: 0px;
  border-radius: 16px;
  filter: drop-shadow(0 0 36.5px rgba(0, 0, 0, 0.06));
  background-color: #ffffff;
  padding: 25px 33px;
}
@media (max-width: 991px) {
  .login-card .card-inner {
    max-width: 500px;
    margin: 0 auto;
  }
}
.login-card.applicant-login {
  filter: none;
}
@media (max-width: 991px) {
  .login-card {
    margin-top: 0;
  }
}
.login-card .head h3 {
  font-size: 21px;
  color: #003661;
  font-weight: 700;
  font-family: "Poppins";
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
}
@media (max-width: 574px) {
  .login-card .head h3 {
    font-size: 19px;
  }
}
.login-card .card-content .nav-tabs {
  height: 47px;
  border-radius: 23px;
  background-color: #f0daac;
  width: 100%;
  padding: 3px;
  margin-bottom: 25px;
}
.login-card .card-content .nav-tabs .nav-item {
  width: 50%;
  height: 100%;
}
.login-card .card-content .nav-tabs .nav-link {
  height: 39px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #003661;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-card .card-content .nav-tabs .nav-link img {
  margin-right: 3px;
  max-width: 18px;
}
.login-card .card-content .nav-tabs .nav-link img.active {
  display: none;
}
.login-card .card-content .nav-tabs .nav-link:focus {
  border-color: transparent;
}
.login-card .card-content .nav-tabs .nav-link:hover {
  border-color: transparent;
}
.login-card .card-content .nav-tabs .nav-link.active {
  height: 39px !important;
  border-radius: 20px;
  background-color: #8d6719;
  color: #ffffff;
  height: 100%;
  width: 100%;
}
.login-card .card-content .nav-tabs .nav-link.active img.deactive {
  display: none;
}
.login-card .card-content .nav-tabs .nav-link.active img.active {
  display: block;
}
@media (max-width: 574px) {
  .login-card {
    position: relative;
    z-index: 1;
    padding: 20px 15px;
  }
}

.form-field {
  margin-top: 0px;
}
.form-field .form-group .form-control {
  height: 42px;
  width: 100%;
  border-radius: 9px;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  color: #000;
  font-size: 12px;
}
@media (max-width: 1439px) {
  .form-field .form-group .form-control {
    height: 40px;
    font-size: 12px;
  }
}
.form-field .form-group .form-control::placeholder {
  color: #797979;
}
.form-field .form-group textarea {
  min-height: 80px;
}
.form-field .form-group.caret {
  position: relative;
}
.form-field .form-group.caret .fa-caret-down {
  font-size: 20px;
  color: #8d6719;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.form-field .form-check {
  margin-bottom: 30px;
}
.form-field .form-check .form-check-label {
  font-size: 12px;
  color: #797979;
  font-weight: 400;
  word-break: keep-all;
}
.form-field .custom-checkbox .form-check-input:checked ~ .custom-control-label::before {
  width: 20px;
  height: 20px;
  background-color: #d2b271;
}
.form-field .custom-checkbox .form-check-input:checked ~ .custom-control-label::after {
  width: 20px;
  height: 20px;
}
.form-field .theme-btn {
  padding: 0 20px;
}
.form-field .theme-btn .btn {
  background-color: #003661;
  border-radius: 0;
  padding: 6px 20px 8px 20px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 6px;
  border-radius: 10px;
  background-color: #8d6719;
  font-size: 17px;
  color: #ffffff;
  font-weight: 500;
  width: 100%;
}
@media (max-width: 1439px) {
  .form-field .theme-btn .btn {
    padding: 6px 20px 8px 20px;
    font-size: 15px;
  }
}
@media (max-width: 574px) {
  .form-field .theme-btn .btn {
    min-width: 100%;
  }
}
@media (max-width: 574px) {
  .form-field .theme-btn {
    padding: 0 0;
  }
}

.points {
  position: relative;
}
.points .container {
  z-index: 99;
  position: relative;
}
.points.before::before {
  content: "";
  position: absolute;
  background-image: url(../images/points-circle.svg);
  height: 450px;
  width: 450px;
  background-size: cover;
  top: 0;
  right: 0;
}
.points.before.squar::before {
  background-image: url(../images/points-squer.svg);
  height: 260px;
  width: 70px;
  top: -72px;
}
.points.before.squar::after {
  width: 70px;
}
.points.after::after {
  content: "";
  position: absolute;
  background-image: url(../images/points-squer.svg);
  height: 291px;
  width: 119px;
  background-size: cover;
  bottom: -72px;
  left: 0;
}
@media (max-width: 574px) {
  .points.after::after {
    content: none;
  }
}

.jobs-today {
  background-color: #fff;
  padding: 80px 0;
}
.jobs-today .main-warum-box {
  width: 96%;
  margin: 0 0 0 auto;
}
@media (max-width: 991px) {
  .jobs-today .main-warum-box {
    width: 100%;
  }
}
.jobs-today .heading {
  margin-bottom: 29px;
}
@media (max-width: 991px) {
  .jobs-today .tell-us-video .play-box {
    margin-bottom: 40px;
  }
}
.jobs-today .tell-us-video .play-box .play-box-image {
  border-radius: 30px;
  overflow: hidden;
}
.jobs-today .tell-us-video .play-box .play-box-image img {
  height: 358px;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 991px) {
  .job-table {
    margin-bottom: 60px;
  }
}
@media (max-width: 574px) {
  .job-table {
    margin-bottom: 40px;
  }
}
.job-table .job-titles {
  display: flex;
  align-items: center;
}
.job-table .job-titles .img {
  width: 51px;
  height: 51px;
  min-width: 51px;
  min-height: 51px;
  border-radius: 9px;
  background-color: #ecce91;
  text-align: center;
  padding: 10px;
  margin-right: 10px;
}
.job-table .job-titles .content p {
  font-size: 15px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 0;
}
@media (max-width: 375px) {
  .job-table .job-titles .content p {
    font-size: 12px;
  }
}
.job-table .job-titles.count-sec {
  display: block;
}
.job-table .job-titles.count-sec .img {
  margin: 0 auto 20px auto;
}
.job-table .job-titles.count-sec .content p {
  color: #ffffff;
  text-align: center;
}

.table-border.browse-table.new {
  margin-top: 50px;
}
.table-border.browse-table .content p {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 0;
}
.table-border.browse-table.find-job .job-titles .img {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 51px !important;
}
.table-border.browse-table.find-job .job-titles .img img {
  max-height: 30px !important;
  max-width: 30px !important;
}
.table-border.browse-table.find-job .job-titles .content p {
  word-break: break-word;
  text-align: left !important;
}
@media (max-width: 1599px) {
  .table-border.browse-table.find-job .job-titles .content p {
    font-size: 15px;
  }
}
.table-border.browse-table.find-job .boxes {
  width: 50% !important;
  border-color: #add0ff !important;
}
@media (max-width: 574px) {
  .table-border.browse-table.find-job .boxes {
    width: 100% !important;
  }
}

.table-border.browse-table .boxes-head {
  display: flex;
  flex-wrap: wrap;
  border-collapse: collapse;
  width: 100%;
}
.table-border.browse-table .boxes-head .box-devide {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.table-border.browse-table .boxes-head .box-devide.one .boxes {
  border-top: 0;
}
.table-border.browse-table .boxes-head .box-devide.one .boxes:first-child {
  border-left: none;
  padding-left: 0;
}
@media (max-width: 991px) {
  .table-border.browse-table .boxes-head .box-devide.one .boxes:first-child {
    border-left: 1px solid #f0daac;
    padding-left: 20px;
  }
}
@media (max-width: 574px) {
  .table-border.browse-table .boxes-head .box-devide.one .boxes:first-child {
    padding: 20px 15px;
  }
}
.table-border.browse-table .boxes-head .box-devide.one .boxes:last-child {
  border-right: none;
}
@media (max-width: 991px) {
  .table-border.browse-table .boxes-head .box-devide.one .boxes:last-child {
    border-right: 1px solid #f0daac;
  }
}
@media (max-width: 991px) {
  .table-border.browse-table .boxes-head .box-devide.one .boxes {
    border-top: 1px solid #f0daac;
  }
}
.table-border.browse-table .boxes-head .box-devide.two .boxes {
  border-bottom: 0;
}
.table-border.browse-table .boxes-head .box-devide.two .boxes:first-child {
  border-left: none;
  padding-left: 0;
}
@media (max-width: 991px) {
  .table-border.browse-table .boxes-head .box-devide.two .boxes:first-child {
    border-left: 1px solid #f0daac;
    padding-left: 20px;
  }
}
@media (max-width: 574px) {
  .table-border.browse-table .boxes-head .box-devide.two .boxes:first-child {
    padding: 20px 15px;
  }
}
.table-border.browse-table .boxes-head .box-devide.two .boxes:last-child {
  border-right: none;
}
@media (max-width: 991px) {
  .table-border.browse-table .boxes-head .box-devide.two .boxes:last-child {
    border-right: 1px solid #f0daac;
  }
}
@media (max-width: 991px) {
  .table-border.browse-table .boxes-head .box-devide.two .boxes {
    border-bottom: 1px solid #f0daac;
  }
}
.table-border.browse-table .boxes-head .box-devide .boxes {
  width: 20%;
  border: 1px solid #f0daac;
  padding: 20px;
}
@media (max-width: 1599px) {
  .table-border.browse-table .boxes-head .box-devide .boxes {
    padding: 20px 20px;
  }
}
@media (max-width: 991px) {
  .table-border.browse-table .boxes-head .box-devide .boxes {
    width: 100%;
    margin-bottom: 30px;
    padding: 20px 20px;
  }
}
@media (max-width: 574px) {
  .table-border.browse-table .boxes-head .box-devide .boxes {
    padding: 20px 15px;
  }
}
.table-border.browse-table .boxes-head .job-titles .content p {
  text-align: center;
}
.table-border.browse-table .boxes-head .job-titles .img {
  text-align: center;
  min-height: 60px;
}
.table-border.browse-table .boxes-head .job-titles .img img {
  max-width: 60px;
  max-height: 53px;
}

.heading {
  margin-bottom: 0;
}
.heading P {
  font-size: 13px;
  letter-spacing: 2px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.heading h4 {
  font-size: 33px;
  color: #8d6719;
  font-weight: 400;
  margin-bottom: 15px;
}
@media (max-width: 574px) {
  .heading h4 {
    font-size: 28px;
  }
}
@media (max-width: 375px) {
  .heading h4 {
    font-size: 22px;
  }
}
.heading h2 {
  color: #003661;
  font-size: 33px;
  margin-bottom: 15px;
}
@media (max-width: 574px) {
  .heading h2 {
    font-size: 25px;
  }
}
@media (max-width: 375px) {
  .heading h2 {
    font-size: 20px;
  }
}

.job-and-task {
  background: #fff6e4;
  position: relative;
  padding: 100px 0 40px 0;
}
@media (max-width: 574px) {
  .job-and-task {
    padding: 50px 0 0px 0;
  }
}
.job-and-task .row {
  justify-content: center;
}
.job-and-task .box {
  margin-bottom: 60px;
}
@media (max-width: 574px) {
  .job-and-task .box {
    margin-bottom: 30px;
  }
}
.job-and-task .box .box-imge {
  width: 113px;
  margin: 0 auto 30px auto;
  text-align: center;
}
.job-and-task .box .box-imge img {
  max-width: 102px;
  max-height: 75px;
  margin: 0 auto;
}
@media (max-width: 574px) {
  .job-and-task .box {
    margin-bottom: 40px;
  }
}
.job-and-task .box .box-content h4 {
  font-size: 21px;
  color: #f0daac;
  font-weight: 500;
  text-align: center;
  margin-bottom: 25px;
}
@media (max-width: 991px) {
  .job-and-task .box .box-content h4 {
    font-size: 19px;
  }
}
.job-and-task .box .box-content p {
  font-size: 15px;
  color: #f0daac;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0;
  max-width: 282px;
  word-break: break-word;
  margin: 0 auto;
}

.browse-profile {
  padding: 80px 0 40px 0;
  background-color: #fff;
}
.browse-profile .heading h4 {
  max-width: 941px;
  margin: 0 auto;
}

.post-job {
  background-color: #d2b271;
  padding: 55px 0;
}

.tell-us-video {
  position: relative;
  z-index: 9;
}
.tell-us-video .play-box {
  margin-top: 110px;
}
@media (max-width: 991px) {
  .tell-us-video .play-box {
    text-align: center;
  }
}
.tell-us-video .play-box .play-box-image img {
  height: 309px;
  width: 100%;
  object-fit: cover;
}
.tell-us-video .play {
  position: relative;
}
.tell-us-video .play .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.tell-us-video .img-box img {
  height: 208px;
  width: 100%;
  object-fit: cover;
}
.tell-us-video .tell-points {
  height: 60%;
  width: 64%;
  position: absolute;
  top: 55px;
  left: 0;
  z-index: -1;
}

.count-sec .img {
  position: relative;
}
.count-sec .img .count {
  width: 25px;
  height: 25px;
  border-radius: 13px;
  background-color: #75520a;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 800;
  position: absolute;
  bottom: 0;
  right: -10px;
}

.job-content-box {
  margin-top: 110px;
}
@media (max-width: 991px) {
  .job-content-box {
    margin-top: 0;
  }
}
.job-content-box .heading p {
  color: #f0daac;
}
.job-content-box .heading h4 {
  color: #fff;
  margin-bottom: 10px;
}
.job-content-box .heading h2 {
  color: #f0daac;
}
.job-content-box .heading h2 span {
  font-weight: normal;
  font-family: "Poppins-Regular";
}

.company-slider-top {
  display: none;
  background-color: #f7f7f7;
  padding: 50px 0 70px 0;
}
.company-slider-top .heading h4 {
  margin-bottom: 20px;
}
@media (max-width: 574px) {
  .company-slider-top {
    padding: 50px 0;
  }
}

.top-comapnies .brand {
  text-align: center;
}
.top-comapnies .brand .image img {
  max-height: 52px;
  filter: grayscale(1);
}
.top-comapnies .brand .content h3 {
  font-size: 16px;
  margin-bottom: 10px;
}
.top-comapnies .brand .content p {
  font-size: 14px;
  margin-bottom: 0;
}
.top-comapnies .brand .content p .fa {
  margin-right: 5px;
}
.top-comapnies .slick-slide img {
  margin: 0 auto 10px auto;
}
.top-comapnies .slick-dots li button:before {
  top: 25px;
  font-size: 10px;
}
@media (max-width: 574px) {
  .top-comapnies .heading h4 {
    font-size: 22px;
  }
}
@media (max-width: 375px) {
  .top-comapnies .heading h4 {
    font-size: 17px;
  }
}

.company-slider {
  overflow: hidden;
}
.company-slider .item {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.blogs-box {
  margin-bottom: 40px;
}
@media (max-width: 574px) {
  .blogs-box {
    margin-bottom: 40px;
  }
}
.blogs-box .image {
  position: relative;
}
.blogs-box .image img {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
.blogs-box .image .date-sec {
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 160px;
  height: 32px;
  background-color: #f0daac;
  text-align: center;
  line-height: 32px;
}
.blogs-box .image .date-sec p {
  font-size: 13px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 0;
}
.blogs-box .content h2 {
  font-size: 17px;
  color: #0c3c7d;
  font-weight: 600;
  margin: 20px 0 15px 0;
}
.blogs-box .user {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blogs-box .user .user-image {
  display: flex;
  align-items: center;
}
.blogs-box .user .user-image .img {
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  overflow: hidden;
  border-radius: 14px;
  margin-right: 10px;
}
.blogs-box .user .user-image p {
  font-size: 13px;
  color: #0c3c7d;
  font-weight: 400;
  margin-bottom: 0;
}
.blogs-box .user .read-more a {
  font-size: 13px;
  color: #8d6719;
  font-weight: 700;
}

.blogs {
  padding: 40px 0 40px 0;
  background-color: #ffffff;
}

.banner.newsletter {
  padding: 40px 0;
  overflow: hidden;
  background-color: #f0daac;
}
.banner.newsletter .login-card {
  background-color: transparent;
  padding: 0;
}
.banner.newsletter .form-field {
  max-width: 450px;
  margin: 0 auto;
}
.banner.newsletter .form-field .theme-btn {
  padding: 0;
}
.banner.newsletter .form-field .theme-btn .btn {
  padding: 6px 29px 8px 29px;
  min-width: 120px;
}
.banner.newsletter .form-field .news-letter-box {
  display: flex;
  align-items: baseline;
  justify-content: center;
}
@media (max-width: 574px) {
  .banner.newsletter .form-field .news-letter-box {
    flex-wrap: wrap;
  }
}
.banner.newsletter .form-field .news-letter-box .form-group {
  margin-right: 10px;
  width: 100%;
}
@media (max-width: 574px) {
  .banner.newsletter .form-field .news-letter-box .theme-btn {
    width: 100%;
    margin-bottom: 20px;
  }
}
.banner.newsletter .form-field .custom-control {
  text-align: center;
}
.banner.newsletter .form-field .custom-control .custom-control-label {
  padding: 5px 0 0 0;
  color: #000;
}
.banner.newsletter .heading h4 {
  font-size: 21px;
}
.banner .banner-person {
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.footer-login .login-card {
  padding-top: 50px;
  margin-top: 0px;
  border-radius: 0px;
  filter: none;
  background-color: #ffffff;
}
.footer-login .login-card.bg-brown {
  background-color: #f0daac;
}
.footer-login .login-card.bg-brown .card-content .nav-tabs {
  background-color: #ffefce;
}
@media (max-width: 574px) {
  .footer-login .login-card {
    padding-top: 0px;
  }
}
.footer-login .login-card .head {
  margin-top: 18px;
}
.footer-login .login-card .head h3 {
  margin-bottom: 15px;
}
.footer-login .login-card .nav-tabs,
.footer-login .login-card .form-group,
.footer-login .login-card .form-check,
.footer-login .login-card .theme-btn {
  max-width: 287px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .footer-login .login-card .nav-tabs {
    margin-bottom: 25px !important;
  }
  .footer-login .login-card .form-check {
    margin-bottom: 9px !important;
  }
}
.footer-login .form-field .custom-control {
  margin-bottom: 17px;
}
.footer-login .theme-btn {
  padding: 0;
}

footer {
  background-color: #75520a;
  padding: 30px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}
@media (max-width: 574px) {
  footer {
    margin-bottom: 20px;
    position: unset;
  }
}
footer .copy-right p {
  font-size: 13px;
  color: #ffffff;
  margin-bottom: 0;
  text-align: right;
}
@media (max-width: 375px) {
  footer .copy-right p {
    font-size: 12px;
  }
}
@media (max-width: 574px) {
  footer .copy-right {
    margin-bottom: 20px;
  }
}
footer .link {
  margin: 0 auto;
  width: intrinsic;
  width: -moz-max-content;
  width: -webkit-max-content;
}
footer .link nav {
  text-align: right;
}
footer .link nav a {
  font-size: 13px;
  color: #ffffff !important;
  margin-bottom: 0;
  text-decoration: none;
}
@media (max-width: 991px) {
  footer .link nav a {
    font-size: 12px;
  }
}
@media (max-width: 375px) {
  footer .link nav a {
    font-size: 12px;
  }
}
@media (max-width: 574px) {
  footer .link nav a {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
  footer .link nav a span {
    display: none;
  }
}
footer .social nav {
  margin: 0 -5px;
  text-align: right;
}
@media (max-width: 574px) {
  footer .social nav {
    text-align: center;
  }
}
footer .social nav a {
  margin: 0 5px;
}
footer .social nav a img {
  width: auto;
  max-height: 16px;
}

.form-steps .radios-and-checks .heading-top-step h2 {
  font-size: 21px;
  letter-spacing: 3px;
  color: #003661;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 27px;
  text-transform: uppercase;
}

.sign-auth-form {
  overflow: hidden;
}
.sign-auth-form .login-top {
  background-color: #ffffff;
}
.sign-auth-form .login-top .login-form.steps-width {
  padding: 0px 85px 40px 85px !important;
}
.sign-auth-form .login-top .login-form {
  max-width: 650px;
  margin: 0 auto;
  padding: 0px 0 71px 0;
  min-height: 840px;
}
.sign-auth-form .login-top .login-form .form-field {
  padding-top: 30px;
}
@media (max-width: 574px) {
  .sign-auth-form .login-top .login-form .form-field {
    padding-top: 40px;
  }
}
.sign-auth-form .login-top .login-form .form-field .tab-pane .form-field {
  padding-top: 30px;
}
@media (max-width: 991px) {
  .sign-auth-form .login-top .login-form {
    margin: 0 0 0px 0px;
    padding: 50px 20px 40px 20px;
  }
}
@media (max-width: 574px) {
  .sign-auth-form .login-top .login-form {
    padding: 40px 15px 40px 15px;
    min-height: auto;
  }
}
.sign-auth-form .login-side-image {
  position: absolute;
  left: 160px;
  right: 0;
  top: 67%;
  max-width: 540px;
}
@media (max-width: 574px) {
  .sign-auth-form .login-side-image {
    min-height: auto;
  }
}
@media (max-width: 767px) {
  .sign-auth-form .login-side-image img {
    height: 400px;
    width: 100%;
    object-fit: cover;
  }
}
@media (max-width: 574px) {
  .sign-auth-form .login-side-image img {
    height: 260px;
  }
}
.sign-auth-form .login-side-image .image-tag-top {
  height: 100%;
}
@media (max-width: 574px) {
  .sign-auth-form .login-side-image .image-tag-top {
    padding: 0px 15px;
    display: none;
  }
}
.sign-auth-form .login-side-image .image-tag-top .img-tag {
  padding: 40px 30px 30px 30px;
  background-color: #fffbf4;
}
@media (max-width: 1599px) {
  .sign-auth-form .login-side-image .image-tag-top .img-tag {
    top: 40%;
    width: 80%;
  }
}
@media (max-width: 991px) {
  .sign-auth-form .login-side-image .image-tag-top .img-tag {
    padding: 20px 15px 30px 15px;
    width: 45%;
  }
}
@media (max-width: 574px) {
  .sign-auth-form .login-side-image .image-tag-top .img-tag {
    width: 100%;
    position: relative;
    margin: 40px 0;
    top: 0%;
  }
}
.sign-auth-form .login-side-image .image-tag-top .img-tag h2 {
  font-size: 36px;
  color: #966b14;
  word-wrap: break-word;
  margin-bottom: 10px;
}
@media (max-width: 991px) {
  .sign-auth-form .login-side-image .image-tag-top .img-tag h2 {
    font-size: 25px;
  }
}
@media (max-width: 375px) {
  .sign-auth-form .login-side-image .image-tag-top .img-tag h2 {
    font-size: 20px;
  }
}
.sign-auth-form .login-side-image .image-tag-top .img-tag p {
  font-size: 15px;
  color: #000000;
  font-weight: 400;
  word-break: break-word;
  margin-bottom: 0;
}
@media (max-width: 375px) {
  .sign-auth-form .login-side-image .image-tag-top .img-tag p {
    font-size: 12px;
  }
}
.sign-auth-form .form-field .head h3 {
  font-size: 21px;
  color: #0c0c0c;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}
.sign-auth-form .form-field .head h2 {
  font-size: 21px;
  color: #003661;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.sign-auth-form .form-field .head p {
  font-size: 25px;
  color: #797979;
  font-weight: 400;
}
.sign-auth-form .form-field .theme-btn {
  text-align: center;
  margin: 0 0 0px 0;
  padding: 0;
}
.sign-auth-form .form-field .theme-btn .btn {
  width: auto;
  min-width: 140px;
  margin: 0 auto;
}

.step-num {
  text-transform: uppercase;
}

.forgot-pw {
  text-align: right;
  margin-bottom: 20px;
}
.forgot-pw a {
  font-size: 12px;
  color: #966b14;
  font-weight: 400;
  text-align: right;
}

.dont-hv-ac p {
  text-align: center;
  font-size: 12px;
  line-height: 29px;
  padding-top: 15px;
  color: #797979;
  font-weight: 400;
}
.dont-hv-ac p a {
  font-weight: bold;
  color: #8d6716;
}

.social-part .social {
  display: flex;
  justify-content: center;
}
.social-part .social .img {
  width: 37px;
  height: 37px;
  min-width: 37px;
  min-height: 37px;
  border-radius: 19px;
  text-align: center;
  padding: 6px;
  margin: 0 5px;
  filter: drop-shadow(0px 1px 1px #0000001a);
}
.social-part .social .img img {
  max-width: 18px;
  height: auto;
}
.social-part .social .img.google {
  background-color: #ffffff;
  border: none;
}
.social-part .social .img.facebook {
  background-color: #287ccf;
  border: none;
}
.social-part .line-sec {
  position: relative;
  text-align: center;
  z-index: 9;
  margin: 20px 0;
}
.social-part .line-sec p {
  position: relative;
  z-index: 99;
  font-size: 17px;
  color: #000000;
  margin-bottom: 0;
  padding: 8px 8px;
  background-color: #ffffff;
}
.social-part .line-sec p::after {
  content: "";
  position: absolute;
  background-color: #ebebeb;
  height: 1px;
  width: 43%;
  top: 50%;
  right: 0;
}
.social-part .line-sec p::before {
  content: "";
  position: absolute;
  background-color: #ebebeb;
  height: 1px;
  width: 43%;
  top: 50%;
  left: 0;
}
.social-part .line-sec::after {
  content: "";
  position: absolute;
  background-color: #ebebeb;
  height: 1px;
  width: 31%;
  top: 50%;
  right: 0;
}
.social-part .line-sec::before {
  content: "";
  position: absolute;
  background-color: #ebebeb;
  height: 1px;
  width: 31%;
  top: 50%;
  left: 0;
}

.upload-box .theme-btn.file {
  position: relative;
  overflow: hidden;
  width: intrinsic;
  width: -moz-max-content;
  width: -webkit-max-content;
  margin: 20px 0;
}
.upload-box .theme-btn.file input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
}
.upload-box .theme-btn .btn {
  font-size: 17px;
}
.upload-box .upload-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 158px;
  height: 84px;
  border-radius: 9px;
  background-color: #fff7e5;
}
.upload-box .upload-img .img-placeholder {
  height: 20px;
  width: 25px;
}

.sign-auth-form {
  background-size: 100%;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
  overflow-y: auto;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .sign-auth-form {
    background-size: 100%;
  }
}

.radios-and-checks .heading-top h2 {
  font-size: 21px;
  color: #003661;
  font-weight: 700;
  margin-bottom: 30px;
}
@media (max-width: 574px) {
  .radios-and-checks .heading-top h2 {
    font-size: 18px;
  }
}
@media (max-width: 375px) {
  .radios-and-checks .heading-top h2 {
    font-size: 16px;
  }
}
.radios-and-checks .radios-and-checks-box .heading h2 {
  font-size: 21px;
  color: #000000;
  line-height: 32px;
  font-weight: 700;
  word-break: break-word;
  margin-bottom: 27px;
}
@media (max-width: 574px) {
  .radios-and-checks .radios-and-checks-box .heading h2 {
    font-size: 16px;
  }
}
@media (max-width: 375px) {
  .radios-and-checks .radios-and-checks-box .heading h2 {
    font-size: 14px;
  }
}
@media (max-width: 574px) {
  .radios-and-checks .radios-and-checks-box .col-6 {
    flex: 0 0 auto;
    width: 100% !important;
  }
}
.radios-and-checks .radios-and-checks-box .custom-control {
  display: flex;
  margin-bottom: 16px;
}
.radios-and-checks .radios-and-checks-box .custom-control label {
  font-size: 17px;
  padding-left: 5px;
  word-break: break-word;
}
.radios-and-checks .radios-and-checks-box .custom-control .form-check-input {
  margin: 3px 7px 0 0;
  min-width: 1em;
  position: absolute;
  left: 0;
  border: 1px solid #212529;
}
.radios-and-checks .radios-and-checks-box .custom-control .custom-control-label {
  font-size: 12px;
  color: #797979;
  font-weight: 400;
  padding-top: 0px;
}
.radios-and-checks .radios-and-checks-box .custom-control .custom-control-label::before {
  background-color: #ffffff;
  border: 1px solid #181859;
}
.radios-and-checks .custom-radio .form-check-input:checked ~ .custom-control-label::before {
  background-color: #8d6719;
}
.radios-and-checks .custom-checkbox .form-check-input:checked ~ .custom-control-label::before {
  width: 1rem;
  height: 1rem;
}
.radios-and-checks .custom-checkbox .form-check-input:checked ~ .custom-control-label::after {
  width: 1rem;
  height: 1rem;
}

.flex-property {
  display: flex;
  align-items: center;
}

.timeline {
  padding: 20px 0 50px 0;
  background-color: #fff;
}

.timeline-box {
  max-width: 710px;
  margin: 0 auto;
  position: relative;
  margin-top: 50px;
}
.timeline-box .box {
  position: relative;
  min-height: 150px;
}
@media (max-width: 574px) {
  .timeline-box .box {
    padding: 0px 0px 60px 95px;
  }
}
.timeline-box .box::before {
  content: "";
  left: 50%;
  position: absolute;
  top: 0%;
  transform: translateX(-50%);
  background-color: #8d6719;
  width: 2px;
  height: 100%;
}
@media (max-width: 574px) {
  .timeline-box .box::before {
    left: 40px;
  }
}
.timeline-box .box:nth-child(even) .content-box {
  margin: 0 0 0 auto;
}
.timeline-box .box:last-child::before {
  content: none;
}
.timeline-box .box .image-box {
  width: 77px;
  height: 77px;
  min-width: 77px;
  min-height: 77px;
  border-radius: 39px;
  background-color: #f0daac;
  border: 1px solid #8d6719;
  text-align: center;
  line-height: 70px;
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translateX(-50%);
}
@media (max-width: 574px) {
  .timeline-box .box .image-box {
    left: 0px;
    top: 0%;
    transform: translateX(0%);
  }
}
.timeline-box .box .image-box img {
  max-width: 40px;
  max-height: 40px;
}
.timeline-box .box .content-box {
  border-radius: 20px;
  background-color: #fff6e4;
  width: 40%;
  padding: 20px 20px;
  min-height: 50px;
}
@media (max-width: 574px) {
  .timeline-box .box .content-box {
    width: 100%;
  }
}
.timeline-box .box .content-box p {
  font-size: 18px;
  color: #9d8044;
  font-weight: 400;
  margin-bottom: 0;
  word-break: break-word;
}
@media (max-width: 574px) {
  .timeline-box .box .content-box p {
    font-size: 16px;
  }
}
.timeline-box .box .content-box .theme-btn .btn {
  margin-top: 15px;
  background-color: #8d6719;
  font-size: 12px;
}

.count-nim-top {
  padding: 80px 0 40px 0;
  background-color: #fff;
}

.count-number {
  margin-bottom: 40px;
  text-align: center;
}
.count-number h2 {
  font-size: 42px;
  color: #003661;
  font-weight: 700;
  margin-bottom: 20px;
}
.count-number p {
  font-size: 21px;
  color: #8d6719;
  font-weight: 400;
  margin-bottom: 0;
  word-break: keep-all;
}

.top-header {
  background-color: #ffffff;
  padding: 26px 15px;
  width: 100%;
  box-shadow: 0px 2px 9px #9a9a9a29;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
}
.top-header .back-new {
  width: 35px;
  height: 33px;
  border: 1px solid #6034f5;
  border-radius: 6px;
  text-align: center;
  line-height: 28px;
  color: #6034f5;
  cursor: pointer;
  position: relative;
  z-index: 99;
  font-size: 20px;
  transition: 0.3s;
  background: #fff;
}
.top-header .top-search {
  width: 100%;
  transition: 0.5s;
}
.top-header .top-search input::placeholder {
  color: #9ea0a5;
  font-size: 14px;
  font-family: AirbnbCerealApp;
}
.top-header .top-search input::before {
  content: "";
  font-family: FontAwesome;
}
.top-header .side-menu-icon {
  margin-top: 5px;
  background-color: #2aabfa;
  padding: 6px 5px 6px 8px;
  margin-right: 20px;
}
.top-header .user-top {
  justify-content: flex-end;
  margin-top: 4px;
  width: fit-content;
  margin: 0 15px 0 auto;
}
.top-header .user-id a {
  color: #708bb0;
  text-decoration: none;
  font-size: 17px;
  padding: 0px 12px 0 0px;
  vertical-align: -webkit-baseline-middle;
  word-break: keep-all;
}

.back-new .fa {
  transition: 0.3s;
}

.header-input {
  width: 100%;
  margin: 0 0 0 auto;
  height: 45px;
  border: none;
  border-radius: 2px;
  padding: 0 0 0 41px;
  background-color: #e2e5ed52;
  position: relative;
  width: 100%;
  position: relative;
  display: flex;
  box-shadow: 0px 2px 22px #7a7a7a1c;
  border: 0.5px solid #d0d0d0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  padding: 5px 6px 6px 40px;
}
.header-input .fa {
  position: absolute;
  color: #9ea0a580;
  font-size: 16px;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
}
.header-input .btn.blue {
  line-height: 18px;
}
.header-input input {
  width: 100%;
  border: none;
  outline: none;
  padding-right: 15px;
}

.user-id .fa {
  color: #404040;
  font-size: 14px;
  vertical-align: -webkit-baseline-middle;
  margin: 2px 0 0 10px;
  cursor: pointer;
  font-size: 20px;
}

.user-top .user-para {
  color: #3f465eb3;
  margin: 7px 28px 1px 9px;
}

.property.side-nav {
  width: 250px;
  z-index: 9;
  overflow: hidden;
  position: fixed;
  height: 100%;
  left: -213px;
  transition: 0.5s;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-color: #003661;
  top: 0px;
  transition: 0.3s;
}
.property.side-nav.full-width .accordion-button {
  border-radius: 9px 9px 9px 9px !important;
}
.property.side-nav.full-width .accordion-button a {
  white-space: nowrap;
  transition: 0s;
}
.property.side-nav.full-width .accordion-body a {
  margin-left: 0px;
  padding-left: 0px !important;
  white-space: nowrap;
}
.property.side-nav.full-width .accordion-item {
  padding-right: 10px !important;
  padding-left: 10px;
  border-radius: 0.25rem;
}
@media (max-width: 1200px) {
  .property.side-nav {
    width: 0px;
  }
}
.property.side-nav .side-nav-menu .accordion-button::after {
  background-image: none;
}
.property.side-nav .side-nav-menu ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
  padding: 14px 0;
}
.property.side-nav .side-nav-menu ul li {
  list-style-type: none;
  padding: 11px 0px 11px 18px;
  position: relative;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}
.property.side-nav .side-nav-menu ul li a {
  width: 220px;
  overflow: hidden;
}
.property.side-nav .side-nav-menu .active {
  position: relative;
}
.property.side-nav .side-nav-menu .active a {
  color: #ffffff;
}
.property.side-nav .side-nav-menu .active span {
  display: initial;
}
.property.side-nav .side-nav-menu .active .icon {
  display: none;
}
.property.side-nav .side-nav-menu a {
  color: #fff;
  font-size: 13px;
  display: block;
  text-decoration: none;
  display: flex;
  flex-wrap: nowrap;
  transition: 0s;
  align-items: center;
  white-space: nowrap;
}
.property.side-nav .side-nav-menu a span {
  margin-right: 18px;
  transition: 0.3s;
  min-width: 26px;
  width: 26px;
}
.property.side-nav .side-nav-menu .accordion-item {
  background-color: transparent;
  padding-right: 18px;
  border: none;
  transition: 0.3s;
}
.property.side-nav .side-nav-menu .accordion-item .accordion-header {
  background-color: transparent;
  padding: 0;
  border-bottom: none;
}
.property.side-nav .side-nav-menu .accordion-item .accordion-header .accordion-button {
  border-radius: 0px 9px 9px 0px;
  background-color: transparent;
  width: 100%;
  text-align: left;
  padding: 13px 17px 13px 17px;
  text-decoration: none;
}
.property.side-nav .side-nav-menu .accordion-item .accordion-header .accordion-button[aria-expanded=true] {
  background-color: #d2b271;
}
.property.side-nav .side-nav-menu .accordion-item .accordion-header .accordion-button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.property.side-nav .side-nav-menu .accordion-item .accordion-body a {
  padding-left: 44px;
  transition: 0.3s;
}
.property.side-nav .side-nav-menu .accordion-item .accordion-body a img {
  width: 22px;
}

.property.side-nav.full-width {
  width: 80px;
}
@media (max-width: 1200px) {
  .property.side-nav.full-width {
    width: 250px;
  }
}
.property.side-nav.full-width .head-2 {
  margin-right: 0px;
}
.property.side-nav.full-width .side-nav-menu a span {
  margin-right: 45px;
}
.property.side-nav.full-width .side-nav-menu .card {
  padding: 0 8px 0 8px;
}
.property.side-nav.full-width .side-nav-menu .card .btn-link {
  border-radius: 4px;
}
.property.side-nav.full-width .logo {
  padding: 36px 19px 45px 19px;
}
.property.side-nav.full-width .logo h3 {
  width: 189px;
}
.property.side-nav.full-width .logo h3 .head-2 {
  margin-right: 20px;
  width: 138px;
}

.trans {
  margin-left: 0px;
}
.trans .fa {
  transform: rotate(180deg);
  line-height: 32px;
}

.head-2 {
  padding: 0 0 0 0px;
  transition: 0.3s;
  margin-right: 3px;
}

.side-slide {
  left: 0px !important;
}

.main-slide .logo-text {
  width: 20%;
}
.main-slide .logo-text h3 {
  color: #3f465e;
  font-size: 21px;
  font-weight: 900;
  margin-top: 9px;
}

main.dash-main {
  margin-left: 250px;
  padding-top: 0px;
}
@media (max-width: 1200px) {
  main.dash-main {
    margin-left: 0px;
  }
}

.setting-text {
  position: fixed;
  width: 100%;
}

.setting-left {
  padding: 127px 0 0 0 !important;
}

.basic-info-right {
  padding: 127px 20px 0 0px !important;
}

.top-user {
  overflow: hidden;
  border-radius: 50%;
  max-width: 40px;
  max-height: 40px;
  border: 1px solid #d2b271;
}
.top-user img {
  height: 100%;
  width: 100%;
  padding: 2px;
}

.side-nav-menu {
  position: relative;
  position: relative;
  height: 100%;
}
.side-nav-menu .logo {
  min-height: 137px;
  transition: 0.3s;
  padding: 36px 30px 35px 30px;
  overflow: hidden;
}
.side-nav-menu .logo h3 {
  display: flex;
  align-items: center;
  width: 189px;
  transition: 0.3s;
}
.side-nav-menu .logo h3 a {
  margin-top: 11px;
}
.side-nav-menu .logo h3 a img {
  width: 100% !important;
}

.main-nav {
  position: relative;
}
.main-nav:before {
  content: "";
  position: absolute;
  height: 20px;
  width: 10px;
  color: #fff;
}

.nav-main {
  position: relative;
  width: 220px;
}

.active-icon {
  display: none;
}

.btn.blue {
  background-color: #582af5;
  font-size: 14px;
  color: #fff;
}

.input-field label {
  color: #1a154d;
  font: 16px;
  font-family: "Gilroy-SemiBold";
}
.input-field input {
  color: #000;
  border: 1px solid #0000000a;
  border-radius: 8px;
}
.input-field input::placeholder {
  color: #1a154d99;
  font-size: 15px;
}

.table-form {
  border: 0.5px solid #00000014;
  padding: 16px 6px 8px 5px;
  border-radius: 8px;
  margin-top: 40px;
}
.table-form .btn.blue {
  margin-top: 34px;
  min-width: 100%;
}

.table-order {
  white-space: nowrap;
  overflow-x: overlay;
  width: 100%;
  box-shadow: 0px 1px 3px #0000000a;
}
.table-order tr {
  border-bottom: 1px solid #00000014;
}
.table-order tr th {
  color: #1a154d;
  font-size: 14px;
  background-color: #f1f6f7;
  padding: 20px 15px;
}
.table-order tr td {
  padding: 20px 15px;
}
.table-order tr td.blue {
  color: #302ea7;
  font-family: "Gilroy-SemiBold";
}
.table-order tr:last-child {
  border-bottom: 2px solid #00000014;
}
.table-order::-webkit-scrollbar {
  width: 2px;
  height: 10px;
  display: none;
}
.table-order:hover::-webkit-scrollbar {
  display: block;
}
.table-order::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.table-order::-webkit-scrollbar-thumb {
  background: #582af5;
}

.nav-tabs li a {
  color: #1a154d99;
  text-decoration: none;
  font-size: 13px;
}
.nav-tabs li a.active {
  background-color: #f25767;
  color: #fff;
  border-radius: 8px;
  padding: 8px 14px;
  font-size: 13px;
}

.tabar-table {
  margin-top: 50px;
}
.tabar-table .text-top h4 {
  color: #1a154d;
  font-size: 28px;
  font-family: "Gilroy-SemiBold";
  margin-bottom: 25px;
}
.tabar-table .tab-content {
  margin-top: 35px;
}
.tabar-table .nav-tabs {
  border-bottom: none !important;
}

.btn.blue.magento {
  background-color: #f25767;
  font-size: 13px;
}

.status .badge-success {
  background-color: #fff;
  color: #35c736;
  border: 1px solid #35c736;
  font-size: 12px;
}

.margin-main {
  margin-left: 80px !important;
}
@media (max-width: 1200px) {
  .margin-main {
    margin-left: 0px !important;
  }
}
@media (max-width: 1200px) {
  .margin-main .back-new {
    margin-left: 250px;
  }
}

main.dash-main {
  transition: 0.3s;
}

.main-page-box {
  padding: 40px 30px;
  min-height: 100vh;
}
@media (max-width: 574px) {
  .main-page-box {
    padding: 40px 15px;
  }
}

.dropdown .drop-inner {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 9px #00000017;
  width: 230px;
  border-radius: 5px;
  padding: 0;
  position: relative;
}

.dropdown .drop-inner:before {
  content: "";
  position: absolute;
  top: -7px;
  right: 15px;
  height: 13px;
  width: 13px;
  background: #f4e8cc;
  transform: rotate(46deg);
  border: solid 1px rgba(156, 156, 156, 0.16);
  border-bottom: none;
  border-right: none;
  z-index: -1;
}

.dropdown .drop-inner a {
  margin: 0;
  color: #003661 !important;
  text-decoration: none;
  size: 10px !important;
  cursor: pointer;
}

.user-top {
  position: relative;
}
.user-top .logout-active {
  display: none;
}
.user-top .drop-inner a {
  display: flex;
  align-items: center;
}
.user-top .drop-inner a:hover {
  color: #d2b271 !important;
}
.user-top .drop-inner a:hover .logout-active {
  display: flex;
}
.user-top .drop-inner a:hover .logout-simp {
  display: none;
}

.user-top .dropdown {
  position: absolute;
  padding: 53px 0 0 0;
  display: none;
  right: -15px;
}

.user-top:hover .dropdown {
  display: block;
}

.user-id a {
  cursor: pointer;
}

.dropdown .drop-inner span {
  vertical-align: top;
  margin-right: 9px;
  display: flex;
}

.user-main-header {
  display: flex;
  align-items: center;
}

.notifications {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  margin-right: 35px;
}
.notifications .box {
  margin-left: 35px;
}
.notifications .box .images {
  width: 25px;
  position: relative;
}
.notifications .box .images .count {
  width: 23px;
  height: 23px;
  min-width: 23px;
  min-height: 23px;
  line-height: 23px;
  border-radius: 11px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  background-color: #8749de;
  position: absolute;
  top: -5px;
  right: -8px;
}

.color-theme {
  background-color: #fff7e5;
}

.dashboard-box {
  border-radius: 31px;
  background-color: #ffffff;
  margin-bottom: 30px;
}

.dash-heading {
  font-size: 17px;
  color: #3c3c3c;
  font-weight: 500;
}

.matches-image-con {
  display: flex;
  align-items: center;
}
.matches-image-con.tab-con-2 {
  height: 100%;
}
.matches-image-con.tab-con-2 .tab-con p {
  font-size: 12px;
}
.matches-image-con.tab-con-2 .tab-con h3 {
  font-size: 12px;
}
@media (max-width: 574px) {
  .matches-image-con {
    margin-bottom: 10px;
    height: auto;
    justify-content: center;
  }
}
.matches-image-con .images {
  max-width: 40px;
  margin-right: 15px;
}
.matches-image-con .images-new {
  margin-right: 15px;
  min-width: 40px;
  width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.matches-image-con .content h3 {
  font-size: 15px;
  color: #3c3c3c;
  font-weight: 500;
  margin-bottom: 0;
}
.matches-image-con .content p {
  font-size: 8px;
  color: #3c3c3c;
  font-weight: 400;
  margin-bottom: 0;
}

.matches-button {
  align-items: center;
  margin: 0 -7px;
}
@media (max-width: 991px) {
  .matches-button {
    margin: 25px -7px 0 -7px;
  }
}
@media (max-width: 767px) {
  .matches-button {
    flex-wrap: wrap;
  }
}
.matches-button .btn-box {
  padding: 0 7px;
}
@media (max-width: 574px) {
  .matches-button .btn-box {
    width: 100%;
    margin-bottom: 20px;
  }
  .matches-button .btn-box:last-child {
    margin-bottom: 0;
  }
}
.matches-button .btn-box .btn {
  border-radius: 10px;
  width: 100%;
  min-height: 41px;
  line-height: 41px;
  text-align: center;
  padding: 0 15px;
  color: #fff;
  font-size: 12px;
}
.matches-button .btn-box .btn img {
  width: 15px;
  margin-right: 10px;
}
.matches-button .btn-box .btn.brown {
  background-color: #c26256;
  min-height: 39px;
}
.matches-button .btn-box .btn.purple {
  background-color: #9d8188;
  min-height: 39px;
}
.matches-button .btn-box .btn.gold {
  background-color: #75520a;
  min-height: 39px;
}
.matches-button .btn-box .btn.chat {
  min-width: 41px;
  min-height: 39px;
  width: 41px;
  height: 39px;
  border-radius: 11px;
  background-color: #003661;
  padding: 0;
  line-height: 34px;
  text-align: center;
}
@media (max-width: 574px) {
  .matches-button .btn-box .btn.chat {
    width: 100%;
  }
}
.matches-button .btn-box .btn.chat img {
  margin: 0;
}

.matches-image {
  max-width: 160px;
  display: flex;
  height: 100%;
  align-items: center;
}
@media (max-width: 574px) {
  .matches-image {
    margin: 0 auto 30px auto;
    height: auto;
    justify-content: center;
  }
}
.matches-image img {
  max-height: 31px;
}

.dash-bx-pad {
  padding: 30px 40px;
}
@media (max-width: 574px) {
  .dash-bx-pad {
    padding: 20px 15px;
  }
}

.new-matches-box {
  padding: 30px 0;
  border-bottom: 1px solid #9d8188;
}
.new-matches-box table {
  white-space: nowrap;
}
.new-matches-box table .tab-con p {
  font-size: 12px;
}
.new-matches-box table .matches-button {
  flex-wrap: nowrap;
  margin: 0px -7px 0 -7px;
}
.new-matches-box table .matches-button .btn-box {
  margin-bottom: 0px;
}
.new-matches-box table .matches-button .btn-box .btn {
  min-width: 91px;
}
.new-matches-box:first-child {
  padding-top: 0;
}
.new-matches-box:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.chat-box {
  border-radius: 16px;
  background-color: #f9f2ed;
  padding: 15px 25px;
  min-height: 71px;
}
@media (max-width: 574px) {
  .chat-box {
    margin-bottom: 20px;
  }
}
.chat-box .matches-image-con {
  margin-top: 5px;
}
.chat-box .content p {
  font-size: 10px;
  color: #3c3c3c;
  font-weight: 400;
  margin-bottom: 0;
  max-width: 375px;
  padding-right: 40px;
}
.chat-box .input-answer {
  position: relative;
}
.chat-box .input-answer input {
  width: 100%;
  height: 24px;
  border: none;
  padding: 0 29px 0 15px;
  background-color: transparent;
  font-size: 10px;
}
.chat-box .input-answer input:focus {
  outline: none;
}
.chat-box .input-answer .send {
  position: absolute;
  top: 5px;
  right: 0px;
  width: 23px;
  height: 23px;
  min-width: 23px;
  min-height: 23px;
  border-radius: 50%;
  background-color: #a14f62;
  padding: 4px;
  line-height: 1px;
}
.chat-box .input-answer .send.like {
  right: 35px;
  background-color: #9d8188;
}
.chat-box .images {
  max-width: 28px;
}

.graph {
  overflow: hidden;
  position: relative;
}
.graph::after {
  content: "";
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
}
.graph.color-1:after {
  background-color: #c16053;
}
.graph.color-1 .img-box {
  background-color: #f9ece3;
}
.graph.color-2:after {
  background-color: #9b7f87;
}
.graph.color-2 .img-box {
  background-color: #efeaeb;
}
.graph.color-3:after {
  background-color: #d18e66;
}
.graph.color-3 .img-box {
  background-color: #faf4ef;
}
.graph .flex-box .img-box {
  max-width: 60px;
  max-height: 60px;
  min-width: 60px;
  min-height: 60px;
  border-radius: 16px;
  padding: 12px;
}
.graph .box-con h3 {
  color: #000000c4;
  font-size: 30px;
  margin-bottom: 0;
}
.graph.graph-pd {
  padding: 30px 20px;
}
.graph .box-con h3 {
  color: #c26056;
}
.graph .con p {
  font-size: 17px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 0;
}

.graph-pd {
  padding: 30px 20px;
}

.notification-box .box {
  margin-top: 25px;
}
.notification-box .box .matches-image-con .images {
  width: 51px;
  height: 51px;
  min-width: 51px;
  max-height: 51px;
  border-radius: 20px;
  text-align: center;
  line-height: 50px;
}
.notification-box .box .matches-image-con .images.color_1 {
  background-color: #e5c7c3;
}
.notification-box .box .matches-image-con .images.color_2 {
  background-color: #ffd4b8;
}
.notification-box .box .matches-image-con .content p {
  font-size: 13px;
  color: #3c3c3c;
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 1;
}
.notification-box .box .matches-image-con .content p span {
  font-weight: bold;
}
.notification-box .box .matches-image-con .content h5 {
  font-size: 13px;
  color: #afafaf;
  font-weight: 500;
  margin-bottom: 0;
}
.notification-box .theme-btn.brown .btn {
  border-radius: 8px;
  margin-top: 25px;
  width: 100%;
  font-size: 13px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggles .form-switch {
  margin-left: 20px;
}

.month-dropdown {
  margin-top: 20px;
}
.month-dropdown .select-month select {
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
}

.job-status .grph-image {
  margin-top: 50px;
}
.job-status .grph-image img {
  width: 100%;
  height: 300px;
}
.job-status .points {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
}
.job-status .points p {
  position: relative;
  padding-left: 20px;
  font-size: 13px;
  color: #3e3b3b;
  font-weight: 600;
  margin-bottom: 0;
}
.job-status .points p::before {
  content: "";
  height: 15px;
  width: 15px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border-radius: 50%;
}
.job-status .points p.one::before {
  background-color: #9f8b7b;
}
.job-status .points p.two::before {
  background-color: #3f9ae0;
}
.job-status .points p.three::before {
  background-color: #ff801e;
}

.chats-box-top .box {
  margin-top: 20px;
  padding: 15px 25px;
}
.chats-box-top .box.chat-box .user-masg {
  display: block;
}
.chats-box-top .box .user-head .user {
  display: flex;
  align-items: center;
}
.chats-box-top .box .user-head .user .image {
  position: relative;
}
.chats-box-top .box .user-head .user .image .image-inner {
  height: 44px;
  width: 44px;
  min-height: 44px;
  min-width: 44px;
  border-radius: 50%;
  overflow: hidden;
}
.chats-box-top .box .user-head .user .image .activity {
  width: 9px;
  height: 9px;
  border-radius: 5px;
  background-color: #40c15b;
  border: 1px solid #ffffff;
  position: absolute;
  top: 5px;
  right: 0px;
}
.chats-box-top .box .user-head .user .user-name {
  margin-left: 10px;
}
.chats-box-top .box .user-head .user .user-name p {
  font-size: 13px;
  color: #8f8ca3;
  font-weight: 500;
  margin-bottom: 0;
}
.chats-box-top .box .user-head .user .user-name h3 {
  font-size: 10px;
  color: #787878;
  font-weight: 700;
  margin-bottom: 0;
}
.chats-box-top .box .user-head .time p {
  font-size: 10px;
  color: #8f8ca3;
  font-weight: 400;
  margin-bottom: 0;
}
.chats-box-top .box .user-masg {
  padding-left: 50px;
  position: relative;
  display: none;
}
.chats-box-top .box .user-masg .input-answer {
  position: static;
}
.chats-box-top .box .user-masg .input-answer .send {
  top: 0;
}

.jobs-offer .matches-image-con .images {
  max-width: 47px;
}
.jobs-offer .matches-image-con .info h4 {
  font-size: 15px;
  color: #afafaf;
  font-weight: 500;
  margin: 10px 0 0 0;
  display: flex;
  white-space: nowrap;
  align-items: center;
}
.jobs-offer .matches-image-con .info h4 span {
  margin-right: 10px;
  display: block;
  height: 17px;
  width: 17px;
}
.jobs-offer .matches-image-con .content h3 {
  font-size: 17px;
  color: #0c3c7d;
}
.jobs-offer .matches-image-con .content p {
  font-size: 15px;
  color: #afafaf;
  font-weight: 500;
  margin-top: 5px;
}
.jobs-offer .matches-button {
  justify-content: flex-end;
  height: 100%;
}
.jobs-offer .matches-button .btn-box .btn {
  background-color: #045afb;
  border-radius: 0px;
  font-size: 13px;
  color: #ffffff;
  font-weight: 500;
}

.color-theme-second {
  background-color: #ffffff;
}

.terms-main {
  padding: 40px 0;
}
.terms-main .scrollspy-example {
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.terms-main .scrollspy-example::-webkit-scrollbar {
  display: none;
}
.terms-main .list-group-top {
  padding: 25px 22px;
  background-color: #fff6e4;
  border-radius: 20px;
}
@media (max-width: 991px) {
  .terms-main .list-group-top {
    margin-bottom: 30px;
  }
}
.terms-main .list-group-top h3 {
  font-size: 21px;
  color: #8d6719;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0e1c4;
}
.terms-main .list-group-top .list-group-item {
  background-color: transparent;
  color: #a68a4f;
  padding: 10px 0;
  margin-bottom: -10px;
  text-transform: capitalize;
  border: none;
  display: flex;
  align-items: center;
  font-weight: 700;
}
.terms-main .list-group-top .list-group-item .arrow {
  width: 27px;
  height: 27px;
  border-radius: 9px;
  background-color: #ecce91;
  display: flex;
  padding: 8px;
  margin-right: 15px;
}
.terms-main .list-group-top .list-group-item .arrow .arrow-2 {
  display: none;
}
.terms-main .list-group-top .list-group-item.active {
  color: #003661;
}
.terms-main .list-group-top .list-group-item.active .arrow {
  background-color: #003661;
}
.terms-main .list-group-top .list-group-item.active .arrow .arrow-2 {
  display: block;
}
.terms-main .list-group-top .list-group-item.active .arrow .arrow-1 {
  display: none;
}
.terms-main .tab-pane:last-child {
  min-height: 500px;
}

.terms-content .head-accordian {
  padding: 10px 0 10px 0;
}
.terms-content .head-accordian h3 {
  font-size: 17px;
  color: #bec5cb;
  font-weight: 500;
  margin-bottom: 0px;
}
@media (max-width: 574px) {
  .terms-content .head-accordian h3 {
    font-size: 16px;
  }
}
.terms-content .accordion-button {
  font-size: 21px;
  color: #2e2e2f;
  font-weight: 700;
  margin-bottom: 0;
  padding: 20px 0;
}
@media (max-width: 574px) {
  .terms-content .accordion-button {
    font-size: 16px;
  }
}
.terms-content .accordion-button:focus {
  outline: none;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none !important;
}
.terms-content .accordion-button:not(.collapsed) {
  color: #8d6719;
  background-color: transparent;
}
.terms-content .accordion-button:not(.collapsed)::after {
  background-image: url(../images/down-brown.svg);
}
.terms-content .accordion-body p {
  margin-bottom: 30px;
  font-size: 15px;
  color: #4a4747;
  font-weight: 300;
}
@media (max-width: 574px) {
  .terms-content .accordion-body p {
    font-size: 14px;
  }
}
.terms-content .accordion-body p:last-child {
  margin-bottom: 0;
}
.terms-content .accordion-body {
  padding: 20px 0;
  line-height: 28px;
  font-size: 15px;
}

.accordian-box {
  margin-bottom: 30px;
}
.accordian-box:last-child {
  margin-bottom: 0;
}

.term-banner {
  overflow: hidden;
  padding-bottom: 30px;
}
.term-banner .theme-btn .btn {
  position: relative;
  z-index: 9;
  background-color: #8d6719;
}
.term-banner.before::before {
  content: "" !important;
}

.form-steps {
  /*------------------------*/
  /*----------step-wizard------------*/
  /*---------signup-step-------------*/
}
.form-steps input:focus,
.form-steps button:focus,
.form-steps .form-control:focus {
  outline: none;
  box-shadow: none;
}
.form-steps .form-control:disabled,
.form-steps .form-control[readonly] {
  background-color: #fff;
}
.form-steps .d-flex {
  display: flex;
}
.form-steps .justify-content-center {
  justify-content: center;
}
.form-steps .align-items-center {
  align-items: center;
}
.form-steps .bg-color {
  background-color: #333;
}
.form-steps .signup-step-container {
  padding: 60px 0px 0 0;
}
.form-steps .wizard .nav-tabs {
  position: relative;
  margin-bottom: 60px;
  border-bottom-color: transparent;
  text-align: center;
}
@media (max-width: 574px) {
  .form-steps .wizard .nav-tabs {
    padding: 0px 10px 0 0px;
    margin-left: -20px;
  }
}
.form-steps .wizard > div.wizard-inner {
  position: relative;
  margin-bottom: 50px;
}
.form-steps .connecting-line {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 15px;
  z-index: 1;
}
.form-steps .wizard .nav-tabs > li.active > a,
.form-steps .wizard .nav-tabs > li.active > a:hover,
.form-steps .wizard .nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}
.form-steps span.round-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background: #d9d6cf;
  z-index: 2;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  border: 1px solid #ddd;
}
.form-steps span.round-tab i {
  color: #555555;
}
.form-steps .wizard li.active span.round-tab {
  background: #ba9b5d;
  color: #fff;
  border-color: #ba9b5d;
}
.form-steps .wizard li.active span.round-tab i {
  color: #5bc0de;
}
.form-steps .wizard .nav-tabs > li.active > a i {
  color: #ba9b5d;
}
.form-steps .wizard .nav-tabs > li {
  width: 16.16%;
}
.form-steps .wizard li:after {
  content: " ";
  position: absolute;
  left: 46%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: red;
  transition: 0.1s ease-in-out;
}
.form-steps .wizard .nav-tabs > li {
  width: 16.16%;
}
.form-steps .wizard li:after {
  content: " ";
  position: absolute;
  left: 46%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: red;
  transition: 0.1s ease-in-out;
}
.form-steps .wizard .nav-tabs > li a {
  width: 30px;
  height: 30px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
  background-color: transparent;
  position: relative;
  top: 0;
}
.form-steps .wizard .nav-tabs > li a i {
  position: absolute;
  top: -15px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: 700;
  color: #d9d6cf;
  width: 100%;
}
.form-steps .wizard .nav-tabs > li a:hover {
  background: transparent;
}
.form-steps .wizard .tab-pane {
  position: relative;
  padding-top: 20px;
}
.form-steps .wizard h3 {
  margin-top: 0;
}
.form-steps .prev-step,
.form-steps .next-step {
  font-size: 13px;
  padding: 8px 24px;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
}
.form-steps .next-step {
  background-color: #ba9b5d;
}
.form-steps .skip-btn {
  background-color: #cec12d;
}
.form-steps .step-head {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}
.form-steps .term-check {
  font-size: 14px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .form-steps .sign-content h3 {
    font-size: 40px;
  }
  .form-steps .signup-logo-header .navbar-toggle {
    margin: 0;
    margin-top: 8px;
  }
  .form-steps .signup-logo-header .logo_area {
    margin-top: 0;
  }
  .form-steps .signup-logo-header .header-flex {
    display: block;
  }
}
.form-steps .theme-btn {
  padding: 0;
  margin-top: 40px;
}
.form-steps .theme-btn .btn {
  min-width: 150px;
  width: auto;
  margin-right: 15px;
}
@media (max-width: 574px) {
  .form-steps .theme-btn.last {
    padding: 0 0px;
    display: flex;
    justify-content: space-between;
  }
  .form-steps .theme-btn.last .btn {
    width: 48%;
    min-width: auto;
    margin: 0;
  }
}
.form-steps .heading {
  margin: 30px 0 20px 0;
}
.form-steps .radios-and-checks .heading-top h2 {
  font-size: 21px;
  letter-spacing: 3px;
  color: #000000;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 574px) {
  .form-steps .radios-and-checks .heading-top h2 {
    font-size: 18px;
  }
}
@media (max-width: 375px) {
  .form-steps .radios-and-checks .heading-top h2 {
    font-size: 16px;
  }
}

.form-steps .wizard .nav-tabs li .checked {
  display: none;
}
.form-steps .wizard .nav-tabs li.disabled.completed .count {
  display: none;
}
.form-steps .wizard .nav-tabs li.disabled.completed span.round-tab {
  background: #079118;
  border: 1px solid #079118;
}
.form-steps .wizard .nav-tabs li.disabled.completed span i {
  color: #079118;
}
.form-steps .wizard .nav-tabs li.disabled.completed .checked {
  display: block;
  margin: 0 auto;
}
.form-steps .wizard .nav-tabs li.disabled.completed.active .count {
  display: block;
}
.form-steps .wizard .nav-tabs li.disabled.completed.active .checked {
  display: none;
}
.form-steps .wizard .nav-tabs li.disabled.completed.active span.round-tab {
  background: #ba9b5d;
  border: 1px solid #ba9b5d;
}
.form-steps .wizard .nav-tabs li.disabled.completed.active span i {
  color: #ba9b5d;
}
.form-steps .step5_form {
  max-width: 585px;
}

.form-group {
  margin-bottom: 25px !important;
}

.form-check {
  margin-bottom: 20px !important;
}
.form-check.bold .form-check-label {
  font-size: 17px;
  color: #272727;
  font-weight: 700;
}
@media (max-width: 574px) {
  .form-check.bold .form-check-label {
    font-size: 14px;
  }
}

.form-check-input:checked {
  background-color: #8a816e !important;
  border-color: #8d6719 !important;
}

.attech-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 574px) {
  .attech-box {
    flex-wrap: wrap;
  }
}
.attech-box .attach {
  position: relative;
  padding-right: 15x;
  width: 93%;
}
@media (max-width: 574px) {
  .attech-box .attach {
    width: 100%;
  }
}
.attech-box .attach .form-control {
  padding-right: 120px;
}
@media (max-width: 574px) {
  .attech-box .attach .form-control {
    padding-right: 105px;
  }
}
.attech-box .attach .upload-box {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.attech-box .attach .upload-box .theme-btn {
  height: 100%;
}
.attech-box .attach .upload-box .theme-btn .btn {
  min-width: 110px;
  height: 100%;
  border-radius: 0px 10px 10px 0px;
}
@media (max-width: 574px) {
  .attech-box .attach .upload-box .theme-btn .btn {
    min-width: 90px;
  }
}
.attech-box .attach .upload-box .theme-btn .btn.round {
  height: 31px;
  width: 31px;
  min-height: 31px;
  min-width: 31px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  line-height: 31px;
}
.attech-box .attach .upload-box .theme-btn .btn.round img {
  filter: inherit(-1);
}
@media (max-width: 574px) {
  .attech-box .theme-btn {
    width: 100%;
    text-align: end;
  }
}
.attech-box .theme-btn .btn {
  margin: 0;
}
.attech-box .theme-btn .btn.round {
  margin: 0;
  height: 31px;
  width: 31px;
  min-height: 31px;
  margin-left: 10px;
  min-width: 31px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  padding: 0;
}
@media (max-width: 574px) {
  .attech-box .theme-btn .btn.round {
    margin-top: 15px;
  }
}
.attech-box .theme-btn .btn.round img {
  width: 11px;
  filter: invert(1);
}

@media (max-width: 1599px) {
  .spiner-top {
    min-height: 480px;
    align-items: center;
  }
}

.contact-sec {
  padding: 50px 0 0px 0;
}

.contact-box {
  margin-bottom: 50px;
}
@media (max-width: 574px) {
  .contact-box {
    margin-bottom: 30px;
  }
}
.contact-box.pink {
  background-color: #fffcf7;
}
.contact-box .dash-heading {
  font-size: 21px;
  color: #8d6719;
  font-weight: 700;
  word-break: initial;
  text-align: left;
  line-height: 28px;
  margin-bottom: 25px;
}
.contact-box .detail-image {
  text-align: right;
  max-width: 287px;
  margin: 0 0 0 auto;
}

.for-padding-box {
  padding: 30px 40px 30px 40px;
}

.circle-box {
  height: 47px;
  width: 47px;
  min-height: 47px;
  min-width: 47px;
  border-radius: 50%;
  background-color: #8d6719;
  line-height: 47px;
  text-align: center;
  margin: 0 20px 0 0;
}
.circle-box img {
  width: 16px;
}

.contact-details .box {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.contact-details .box .con p {
  font-size: 15px;
  color: #646464;
  font-weight: 300;
  margin-bottom: 0;
}

.map {
  margin-bottom: 50px;
}
@media (max-width: 574px) {
  .map {
    margin-bottom: 30px;
  }
}

.theme-btn .btn {
  border-radius: 8px;
  padding: 6px 29px 8px 29px;
}
.theme-btn .btn.width-fit {
  min-width: 120px;
  width: auto;
}

.terms-page {
  padding: 50px 0;
}
.terms-page .terms-box {
  margin-bottom: 40px;
}
@media (max-width: 574px) {
  .terms-page .terms-box {
    margin-bottom: 20px;
  }
}
.terms-page .terms-box h3 {
  color: #8d6719;
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: bold;
}
@media (max-width: 574px) {
  .terms-page .terms-box h3 {
    font-size: 18px;
  }
}
.terms-page .terms-box p {
  margin-bottom: 30px;
  font-size: 15px;
  color: #4a4747;
  font-weight: 300;
}
@media (max-width: 574px) {
  .terms-page .terms-box p {
    font-size: 14px;
  }
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: inset !important;
}

.tabledash table tr {
  border-bottom: 1px solid #19198e4a !important;
  background-color: #fff;
}
.tabledash table tr:last-child {
  border-bottom: transparent;
}
.tabledash table tr th {
  padding: 18px 15px;
  background-color: #003661;
  font-size: 12px;
  font-weight: normal;
  color: #fff;
  text-transform: uppercase;
}
.tabledash table tr td {
  color: #080847;
  font-size: 12px;
  background-color: #fff;
  padding: 18px 15px;
}
.tabledash table .action-images {
  display: flex;
}
.tabledash table .action-images span {
  cursor: pointer;
  display: block;
  width: 17px;
  height: 17px;
  margin-right: 10px;
}
.tabledash table .action-images span img {
  height: 100%;
  width: 100%;
}

.loader-wrapper {
  background: rgba(255, 255, 255, 0.84);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
}

.loader {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}

.loader:before {
  content: "";
  width: 100px;
  height: 100px;
  border: 5px solid rgba(0, 0, 0, 0.05);
  border-top-color: #d2b271;
  border-radius: 50%;
  animation: spin 1.5s infinite linear;
  display: block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader img {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.grecaptcha-badge {
  visibility: hidden;
}

body.recaptcha .grecaptcha-badge {
  visibility: visible;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 37px !important;
  font-size: 12px;
  color: #212529;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #212529 !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 8px !important;
  right: 1px;
  width: 20px;
}

.select2-container .select2-selection--single {
  height: 38px !important;
  line-height: 36px !important;
}

.select2-container--default .select2-selection--multiple {
  margin-bottom: 16px;
}

.select2-container--default .select2-selection--single {
  border-radius: 9px !important;
  margin-bottom: 16px;
}

.toast-container .ngx-toastr {
  width: 350px !important;
  word-break: break-word;
}
@media (max-width: 574px) {
  .toast-container .ngx-toastr {
    width: 320px !important;
  }
}

.form-field .form-check .form-check-label a {
  color: #797979;
  text-decoration: none;
}

.content-box .num {
  padding-right: 10px;
  font-size: 19px;
  color: #9d8044;
}

.timeline-box .box .content-box .text-block {
  display: flex;
}

.job-and-task .box .box-content h4,
.job-and-task .box .box-content p {
  color: #9d8044;
}

.contact-box label {
  padding-bottom: 10px;
  color: #8d6719;
  font-size: 14px;
}

.contact-box .form-group input,
.contact-box .form-group textarea {
  border-radius: 0px !important;
  height: 34px !important;
}

.contact-box .form-group textarea {
  min-height: 100px !important;
}

.contact-box .sub-heading {
  font-size: 14px;
  color: #666;
}

.job-and-task::before {
  content: "";
  position: absolute;
  background-image: url(../images/points-squer.svg);
  height: 291px;
  width: 119px;
  background-size: cover;
  top: -72px;
  right: 0;
}
@media (max-width: 574px) {
  .job-and-task::before {
    display: none;
  }
}
@media (max-width: 574px) {
  .job-and-task::before {
    display: none;
  }
}
@media (max-width: 1200px) {
  .job-and-task::before {
    display: none;
  }
}

.job-and-task::after {
  content: "";
  position: absolute;
  background-image: url(../images/points-squer.svg);
  height: 291px;
  width: 119px;
  background-size: cover;
  bottom: -72px;
  left: 0;
}
@media (max-width: 574px) {
  .job-and-task::after {
    display: none;
  }
}
@media (max-width: 574px) {
  .job-and-task::after {
    display: none;
  }
}
@media (max-width: 1200px) {
  .job-and-task::after {
    display: none;
  }
}

.note-block {
  padding-bottom: 20px;
  font-size: 17px;
}

.note-block strong {
  padding-right: 5px;
}

.sign-auth-form .form-field .head h4 {
  font-size: 21px;
  color: #0c0c0c;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
}

.line-heading h4 {
  line-height: 35px;
  font-size: 21px;
}

.radios-and-checks .radios-and-checks-box .heading h4 {
  font-size: 21px;
  color: #0c0c0c;
  font-weight: 400;
  text-align: left;
  margin-bottom: 30px;
}

.login-form.steps-width {
  max-width: 820px !important;
}

.radios-and-checks-box .form-check {
  margin-bottom: 30px;
}
.radios-and-checks-box .form-check .form-check-label {
  font-size: 17px;
  color: #212529;
  font-weight: 400;
  word-break: keep-all;
}

/* --------------- new design  */
.userDropmail {
  padding: 5px 10px;
  background: #fffbf1;
}

.userDropmail .user-id a {
  font-size: 15px;
  color: #708ab0 !important;
  padding: 0 0px 5px;
}

.userDropmail .userMail {
  font-size: 12px;
  color: #708ab0;
}

.user-top .drop-inner > a {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  font-size: 14px;
  color: #708ab0 !important;
  border-bottom: 1px solid rgba(112, 138, 176, 0.4);
}

.user-top .drop-inner > a:last-child {
  border-bottom: 0;
}

.property.side-nav .side-nav-menu a svg {
  width: 17px;
  margin-right: 15px;
  color: #d2b271;
}

.property.side-nav .side-nav-menu .accordion-item .accordion-header .accordion-button[aria-expanded=true] a svg {
  color: #fff;
}

.newThemeBtn {
  background: #75520a;
  color: #fff;
  border-style: solid;
  border-color: #75520a;
  display: inline-block;
  padding: 8px 25px;
  outline: 0;
  border-radius: 10px;
  box-shadow: none;
  font-size: 15px;
  min-width: 100px;
}

.newThemeBtn:hover {
  opacity: 0.8;
}

.newThemeBtn i {
  padding-right: 5px;
}

.codidate_profile_block {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 15px;
}

.codidate_profile_block img {
  opacity: 0;
  display: none;
}

.condidate_name_block,
.condidate_Date_block,
.condidate_interest {
  font-size: 20px;
  color: #003661;
  font-family: "Poppins-SemiBold";
}

.condidate_name_block span:not(.tags),
.condidate_Date_block span:not(.tags),
.condidate_interest span:not(.tags) {
  font-size: 14px;
  color: #99a1a7;
  font-family: "Poppins-Light";
  display: block;
  margin-top: 5px;
}

.condidate_Prfile_name {
  display: flex;
  align-items: center;
}

.condidate_interest {
  line-height: 22px;
}

.table > :not(caption) > * > * {
  padding: 0 !important;
}

.condidate_Table .table th,
.condidate_Table .table td {
  vertical-align: middle;
  border: 0;
  padding: 15px;
  white-space: nowrap;
}

.tags {
  font-size: 12px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  font-family: "Poppins-Regular";
}

.tags.small {
  font-size: 11px;
}

.tags.primary {
  background: #098cf5;
}

.tags.success {
  background: #23d058;
}

.tags.pending {
  background: #f7ae1a;
}

.tags.expire {
  background: #e10909;
}

.condidate_btn_group a {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 0 5px;
}

.condidate_btn_group a:hover {
  text-decoration: none;
  opacity: 0.7;
  color: #fff;
}

.condidate_btn_group a.new_edit {
  background: #003661;
}

.condidate_btn_group a.new_delete {
  background: #ce504f;
}

.condidate_btn_group a.new_view {
  background: #8d6719;
}

.condidate_Table {
  margin-top: 20px;
  padding: 0 7px;
}

.condidate_Table .table {
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0 20px;
}

.condidate_Table .table tr {
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.condidate_Table .table tr td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.condidate_Table .table tr td:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 180px;
  position: relative;
}

.condidate_Table .table tr td:last-child::before {
  position: absolute;
  content: "";
  height: calc(100% - 30px);
  width: 1px;
  background: rgba(153, 161, 167, 0.33);
  left: 0;
  top: 15px;
}

.heading_new {
  font-size: 27px;
  color: #8d6719;
  font-family: "Poppins-SemiBold";
  margin-bottom: 25px;
  display: inline-block;
}

.profile_box {
  background: #fff;
  border-radius: 30px;
  padding: 30px;
}

.compny_profile_img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #e6dbc1;
  position: absolute;
  left: 0;
  top: 0;
}

.compny_profile_img img {
  display: none;
}

.compny_profile_content {
  font-size: 18px;
  color: #003661;
  font-family: "Poppins-SemiBold";
  margin-right: 30px;
  width: 100%;
  max-width: 247px;
}

.compny_profile_content span {
  color: #86949f;
  font-size: 15px;
  display: block;
  font-family: "poppins-Light";
  margin-top: 5px;
}

.profile_box .form-control {
  height: calc(1.5em + 0.75rem + 8px);
  color: #003661;
  font-family: "Poppins-Medium";
  font-size: 15px;
}

.custom_upload_img {
  position: relative;
  flex: 1;
}

.custom_upload_img input {
  padding-right: 90px;
}

.custom_upload_img label {
  font-size: 13px;
  color: #fff;
  background: #8d6719;
  display: inline-block;
  padding: 10px 15px;
  margin-bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  cursor: pointer;
}

.upload_compny_profile {
  display: flex;
  align-items: center;
  padding-left: 105px;
  position: relative;
  padding-bottom: 35px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.compny_other_formField {
  margin-top: 30px;
}

.profile_box label:not(.customLabel) {
  font-size: 16px;
  color: #617789;
  font-family: "Poppins-Light";
  margin-top: 10px;
  word-break: break-word;
}

.profile_box textarea {
  resize: none;
}

.dividerLIne {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  flex: 1;
  margin-left: 25px;
}

.dividerHeading {
  color: #003661;
  font-size: 20px;
  font-family: "Poppins-Regular";
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.newThemeBtn.secondary_newThemeBtn {
  background: #003661;
  border-color: #003661;
}

.profile_box .newThemeBtn.secondary_newThemeBtn {
  margin-right: 15px;
}

.searchField input {
  height: 44px;
  padding: 5px 10px;
  background: #fff;
  border-color: #fff;
  border-style: solid;
  border-radius: 10px;
  box-shadow: 0 0 9px -2px rgba(0, 0, 0, 0.2);
  font-family: "Poppins-Regular";
  padding-right: 35px;
  box-shadow: none;
  outline: 0;
  max-width: 245px;
  color: #003661;
}

.searchField {
  position: relative;
  display: inline-block;
}

.searchField input::placeholder {
  color: #003661;
  font-family: "Poppins-Light";
}

.searchFieldIcon {
  color: #003661;
  font-family: "Poppins-Regular";
  position: absolute;
  right: 10px;
  top: 9px;
  font-size: 17px;
}

.searchFlexGroup {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.searchFlexGroup button {
  margin-left: 10px;
}

.search_order_img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 13px -3px rgba(0, 0, 0, 0.2);
}

.search_order_img img {
  opacity: 0;
  display: none;
}

.search_order_content {
  font-size: 21px;
  color: #003661;
  font-family: "Poppins-SemiBold";
}

.search_order_content span {
  font-size: 14px;
  display: block;
  color: #99a1a7;
  margin-top: 5px;
  font-family: "Poppins-Light";
}

.search_order_content span i {
  color: #003661;
  padding-right: 5px;
}

.top_search_flex {
  display: flex;
  align-items: center;
}

.search_order_img {
  margin-right: 15px;
}

.top_search_order {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.top_search_body .searchLabel {
  color: #8ea1b1;
  font-family: "Poppins-Regular";
  margin-bottom: 15px;
}

.top_search_body .search_label_content {
  color: #003661;
  font-size: 16px;
  font-family: "Poppins-Medium";
  margin-bottom: 15px;
}

.tags_info {
  color: #003661;
  background: #eef5fb;
}

ul.search_feature_card {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

ul.search_feature_card .tags {
  font-size: 13px;
  font-family: "Poppins-Regular";
  margin-right: 10px;
}

.search_order_profile_cardBox .profile_box:not(:last-child) {
  margin-bottom: 20px;
}

ul.pagination {
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 0;
}

ul.pagination .page-link,
ul.pagination .page-item.disabled .page-link {
  background: transparent;
  border: 0;
}

ul.pagination .page-link {
  color: #75520a;
  box-shadow: none;
  outline: 0;
  margin: 5px;
}

ul.pagination .page-link:not(.nameLink) {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 0;
  justify-content: center;
  display: flex;
  align-items: center;
}

ul.pagination .page-item.active .page-link {
  background: #75520a;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.progress-circle {
  position: relative;
  width: 60px;
  height: 60px;
  display: inline-block;
  margin-left: 10px;
}

.progress-circle:before {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  content: attr(data-progress) "%";
  display: flex;
  justify-content: center;
  position: absolute;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
  transition: transform 0.2s ease;
  width: 50px;
  height: 50px;
  font-size: 17px;
  color: #003661;
  font-family: "Poppins-SemiBold";
  z-index: 1;
}

.progress-circle:after {
  content: "";
  background-color: #28a745;
  border-radius: 50%;
  display: inline-block;
  height: 100%;
  width: 100%;
  transform: rotateY(180deg);
  z-index: 1;
}

/* Circle Progress */
.progress-circle[data-progress="0"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(90deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="1"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(93.6deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="2"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(97.2deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="3"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(100.8deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="4"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(104.4deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="5"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(108deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="6"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(111.6deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="7"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(115.2deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="8"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(118.8deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="9"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(122.4deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="10"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(126deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="11"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(129.6deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="12"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(133.2deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="13"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(136.8deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="14"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(140.4deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="15"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(144deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="16"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(147.6deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="17"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(151.2deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="18"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(154.8deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="19"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(158.4deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="20"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(162deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="21"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(165.6deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="22"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(169.2deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="23"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(172.8deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="24"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(176.4deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="25"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(180deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="26"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(183.6deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="27"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(187.2deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="28"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(190.8deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="29"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(194.4deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="30"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(198deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="31"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(201.6deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="32"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(205.2deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="33"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(208.8deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="34"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(212.4deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="35"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(216deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="36"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(219.6deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="37"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(223.2deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="38"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(226.8deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="39"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(230.4deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="40"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(234deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="41"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(237.6deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="42"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(241.2deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="43"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(244.8deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="44"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(248.4deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="45"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(252deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="46"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(255.6deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="47"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(259.2deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="48"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(262.8deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="49"]:after {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(266.4deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="50"]:after {
  background-image: linear-gradient(-90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="51"]:after {
  background-image: linear-gradient(-86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="52"]:after {
  background-image: linear-gradient(-82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="53"]:after {
  background-image: linear-gradient(-79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="54"]:after {
  background-image: linear-gradient(-75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="55"]:after {
  background-image: linear-gradient(-72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="56"]:after {
  background-image: linear-gradient(-68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="57"]:after {
  background-image: linear-gradient(-64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="58"]:after {
  background-image: linear-gradient(-61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="59"]:after {
  background-image: linear-gradient(-57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="60"]:after {
  background-image: linear-gradient(-54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="61"]:after {
  background-image: linear-gradient(-50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="62"]:after {
  background-image: linear-gradient(-46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="63"]:after {
  background-image: linear-gradient(-43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="64"]:after {
  background-image: linear-gradient(-39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="65"]:after {
  background-image: linear-gradient(-36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="66"]:after {
  background-image: linear-gradient(-32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="67"]:after {
  background-image: linear-gradient(-28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="68"]:after {
  background-image: linear-gradient(-25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="69"]:after {
  background-image: linear-gradient(-21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="70"]:after {
  background-image: linear-gradient(-18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="71"]:after {
  background-image: linear-gradient(-14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="72"]:after {
  background-image: linear-gradient(-10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="73"]:after {
  background-image: linear-gradient(-7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="74"]:after {
  background-image: linear-gradient(-3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="75"]:after {
  background-image: linear-gradient(0deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="76"]:after {
  background-image: linear-gradient(3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="77"]:after {
  background-image: linear-gradient(7.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="78"]:after {
  background-image: linear-gradient(10.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="79"]:after {
  background-image: linear-gradient(14.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="80"]:after {
  background-image: linear-gradient(18deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="81"]:after {
  background-image: linear-gradient(21.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="82"]:after {
  background-image: linear-gradient(25.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="83"]:after {
  background-image: linear-gradient(28.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="84"]:after {
  background-image: linear-gradient(32.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="85"]:after {
  background-image: linear-gradient(36deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="86"]:after {
  background-image: linear-gradient(39.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="87"]:after {
  background-image: linear-gradient(43.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="88"]:after {
  background-image: linear-gradient(46.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="89"]:after {
  background-image: linear-gradient(50.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="90"]:after {
  background-image: linear-gradient(54deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="91"]:after {
  background-image: linear-gradient(57.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="92"]:after {
  background-image: linear-gradient(61.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="93"]:after {
  background-image: linear-gradient(64.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="94"]:after {
  background-image: linear-gradient(68.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="95"]:after {
  background-image: linear-gradient(72deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="96"]:after {
  background-image: linear-gradient(75.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="97"]:after {
  background-image: linear-gradient(79.2deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="98"]:after {
  background-image: linear-gradient(82.8deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="99"]:after {
  background-image: linear-gradient(86.4deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.progress-circle[data-progress="100"]:after {
  background-image: linear-gradient(90deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, #efefef 50%, #efefef);
}

.match_card {
  background: #f6f9fb;
  padding: 20px;
  border-radius: 10px;
}

.match_collapse_head {
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  padding-right: 40px;
}

.match_collapse_head::before {
  position: absolute;
  content: "";
  font-family: "";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #1c5c8f;
  display: flex;
  align-items: center;
  color: #fff;
  right: 0;
  top: -3px;
  justify-content: center;
  font-family: "Font-Awesome-5-Pro";
}

.match_collapse_head.collapsed::before {
  content: "";
  background: #7795ad;
}

.match_collapse_head:hover {
  text-decoration: none;
}

.match_coll_Label {
  flex: 0 0 245px;
  font-size: 16px;
  color: #003661;
  font-family: "Poppins-Medium";
}

.new_progress_right {
  flex: 1;
  position: relative;
  padding-right: 43px;
}

.new_progress_value {
  position: absolute;
  font-size: 16px;
  color: #003661;
  font-family: "Poppins-Medium";
  right: 0;
  top: -3px;
}

.match_card_body {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  padding-right: 40px;
}

.match_card_body_progress {
  display: flex;
  align-items: center;
}

.match_card_body_progress .match_coll_Label {
  flex: 0 0 225px;
  font-size: 14px;
  color: #8ea1b1;
  font-family: "Poppins-Regular";
}

.match_card_body_progress:not(:last-child) {
  margin-bottom: 20px;
}

.top_search_body .match_card:not(:last-child) {
  margin-bottom: 20px;
}

.form-group.dateIconProfile {
  position: relative;
}

.form-group.dateIconProfile input {
  padding-right: 45px;
}

.calendarIcon {
  position: absolute;
  font-size: 21px;
  color: #9bacba;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.maleRadio label.genderLabel {
  color: #003661;
  font-size: 15px;
  font-family: "Poppins-Medium";
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.maleRadio label::before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  left: 0;
  top: 3px;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
}

.maleRadio label::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #fff;
  left: 4px;
  top: 7px;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.maleRadio input[type=radio]:checked + label::before {
  background: #8b7d60;
}

.maleRadio input[type=radio]:checked + label::after {
  transform: scale(1);
}

.maleRadio {
  display: inline-block;
}

.maleRadio:not(:first-child) {
  margin-left: 20px;
}

.top_search_body .search_label_content svg {
  width: 23px;
  height: 23px;
  margin-right: 5px;
}

.videoModalPopup {
  cursor: pointer;
}

.interestImgLogo {
  text-align: center;
  margin-bottom: 40px;
  /* width: 100%; */
}

.interestImgLogo img {
  width: 100%;
  max-width: 225px;
}

.interestLogoName {
  color: #003661;
  font-size: 21px;
  font-family: "Poppins-SemiBold";
  text-align: center;
  margin-bottom: 15px;
}

.interestLogoAdd {
  font-size: 14px;
  color: #99a1a7;
  text-align: center;
  font-family: "Poppins-Light";
  margin-bottom: 25px;
}

.borderLineInterest {
  position: relative;
}

.borderLineInterest::before {
  content: "";
  position: absolute;
  right: 30px;
  height: calc(100% + 60px);
  width: 2px;
  top: -30px;
  background: #fff7e5;
}

.form-check {
  padding-left: 2.25rem !important;
}

.top-header .user-top {
  position: relative;
  cursor: pointer;
}

.top-header .user-top::before {
  position: absolute;
  content: "";
  font-family: "Font-Awesome-5-pro";
  right: -15px;
  top: 5px;
  color: #75520a;
  font-size: 17px;
}

/* Notifications */
.notify-wrapper {
  display: flex !important;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 68px 0px rgba(0, 0, 0, 0.03);
  overflow: hidden;
}

.notify-left {
  width: 356px;
  padding: 0 0px 0 0;
  border-right: 2px solid #e8eded;
}

.notify-left .notify-listing {
  padding: 0;
}

.notify-right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.notify-right .card-block {
  background: #fff;
  padding: 18px;
  border-radius: 20px;
  margin: 0;
}

.notify-right .chat-box {
  background: transparent;
  padding: 15px 0;
  padding-bottom: 75px;
  position: relative;
}

.notify-left .card-block {
  padding: 14px;
  margin: 0;
}

.form-search .form-control {
  border: 0;
  padding: 15px;
  background-color: #f3f6f9;
  height: 50px;
  border-radius: 10px;
}

.form-search {
  padding: 15px;
}

ul.messageFilterUsr {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #e8eded;
}

ul.messageFilterUsr li {
  width: 100%;
}

ul.messageFilterUsr li a {
  color: rgba(68, 68, 71, 0.66);
  font-size: 17px;
  font-family: "Poppins-Medium";
  padding: 15px;
  display: block;
  text-align: center;
  text-decoration: none;
  border-bottom: 4px solid transparent;
  margin-bottom: -2px;
}

ul.messageFilterUsr li a.active {
  color: #8d6719;
  border-color: #75520a;
}

.searchBtn {
  position: absolute;
  top: 13px;
  right: 0;
  font-size: 18px;
  color: #a8bec2;
  border: 0;
  background: none;
}

.searchBtn:hover {
  color: #2479bb;
}

.notify-listing {
  margin: 0;
  padding: 0;
  list-style: none;
}

.notify-listing li {
  margin: 2px 0;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: #8f8ca3;
  border-radius: 0;
  cursor: pointer;
}

.notify-listing li:hover {
  background: #f3f6f9;
}

.notify-listing li .notifyuser-list figure {
  width: 40px;
  height: 40px;
  margin: 0 10px 0 0;
  position: absolute;
  top: 11px;
  left: 2px;
}

.notify-listing li.active .notifyuser-list figure::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  right: 0px;
  bottom: 4px;
}

.notify-listing li .notifyuser-list figure img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.staturChat {
  position: relative;
  font-size: 12px;
  color: #868788;
  font-family: "Poppins-Light";
  display: block;
  padding-left: 15px;
}

span.msgNotCnt {
  display: inline-block;
  font-size: 11px;
  color: #fff;
  background: #108ef2;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
  margin-top: 5px;
}

.notify-times {
  width: 62px;
  text-align: right;
  min-width: 62px;
  font-size: 12px;
  color: #5d5f6a;
  font-family: "Poppins-Light";
}

.notifyuser-list {
  position: relative;
  display: flex;
  font-size: 13px;
  flex: 1;
  padding: 10px 0 10px 54px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-wrap: wrap;
  align-items: center;
}

.someMessage {
  display: block;
  width: 100%;
  font-size: 12px;
  color: #a3a8be;
  font-family: "Poppins-Light";
}

.usernames {
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  text-overflow: ellipsis;
  color: #444447;
  min-height: 25px;
  font-family: "Poppins-SemiBold";
}

.notify-users {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e8eded;
  padding: 15px;
  margin: 0 0 21px;
}

.notify-users-left {
  display: flex;
  align-items: center;
  font-size: 22px;
  color: #444447;
  font-weight: 600;
  font-family: "Poppins-SemiBold";
}

.notify-users-left img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 8px 0 0;
}

.active-time {
  font-size: 13px;
  color: #8f8ca3;
}

.notifications-listing {
  margin: 0;
  padding: 0;
  list-style: none;
}

.notifications-listing li {
  margin: 0 0 20px;
  padding: 20px;
  padding-left: 76px;
  list-style: none;
  position: relative;
  background: #d5ffe1;
  border-radius: 19px;
}

.notifications-listing li.msg-notification {
  background: #d5ffe1;
}

.notifications-listing li.class-notification {
  background: #fff5d5;
}

.notifications-listing li.request-notification {
  background: #d5f8ff;
}

.notifications-listing li:last-child {
  margin: 0;
}

.notifications-listing li .notify-icons {
  position: absolute;
  top: 18px;
  left: 20px;
  width: 42px;
}

.notify-header h2 {
  color: #656082;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.notify-header h2 span {
  display: block;
  color: #656082;
  font-size: 12px;
  margin: 4px 0 0;
  font-weight: 300;
}

.notify-header {
  display: flex;
  justify-content: space-between;
}

.notify-actions {
  width: 128px;
  text-align: right;
  min-width: 128px;
}

.delete-notify {
  background: #ff2a00;
  display: inline-block;
  margin: 0 2px 0 0;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.delete-notify:hover {
  background: #d62603;
}

.page-notify {
  background: #009cff;
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
}

.page-notify:hover {
  background: #0787d6;
}

.fav-notify {
  background: #ffc000;
  display: inline-block;
  margin: 0 2px;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
}

.fav-notify:hover {
  background: #ce9b03;
}

.forward-notify {
  background: #065658;
  display: inline-block;
  margin: 0 0 0 2px;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
}

.forward-notify:hover {
  background: #038285;
}

.notify-text {
  margin: 17px 0 0;
}

.notify-text p {
  color: #656565;
  font-size: 13px;
  margin: 0;
}

.notify-users-left figure {
  margin-bottom: 0;
}

/* Inbox Chat  */
.chat-box .chat-room {
  min-height: 540px;
  padding: 0 15px;
  max-height: 550px;
  height: auto;
  overflow: auto;
}

.chat-box .chat-room .avatar-small {
  width: 40px;
}

.date-time {
  display: block;
  text-align: center;
  font-size: 11px;
  color: #adadad;
  margin: 5px 0 0;
  font-family: "Poppins-Regular";
}

.chat-box .chat-room .message {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.chat-box .chat-room .message-left .avatar-small {
  position: absolute;
  left: 0;
  bottom: 21px;
}

.chat-box .chat-room .message-left .avatar-small img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-box .chat-room .message-left {
  align-items: flex-start;
  padding-left: 63px;
  max-width: 80%;
}

.chat-box .chat-room .message-left .bubble {
  border-radius: 5px;
}

.chat-box .chat-room .message-right {
  align-items: flex-end;
  max-width: 62%;
  margin-right: 0;
  margin-left: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.chat-box .chat-room .message-right .bubble {
  border-radius: 5px;
}

.chat-box .chat-room .bubble {
  padding: 11px 22px;
  font-size: 14px;
  margin-top: 5px;
  display: inline-block;
  color: #112433;
  font-family: "Poppins-Regular";
  position: relative;
}

.chat-box .chat-room .bubble-light {
  background-color: #e0f1ff;
}

.chat-box .chat-room .bubble-light::before {
  position: absolute;
  content: "";
  width: 0;
  border-right: 15px solid #e0f1ff;
  border-top: 15px solid transparent;
  border-bottom: 0px solid transparent;
  border-left: 0;
  left: -11px;
  bottom: 0px;
}

.chat-box .chat-room .bubble-dark {
  color: #112433;
  background-color: #f7f1e3;
  margin-right: 11px;
}

.chat-box .chat-room .bubble-dark::before {
  position: absolute;
  content: "";
  width: 0;
  border-left: 15px solid #f7f1e3;
  border-top: 15px solid transparent;
  border-bottom: 0px solid transparent;
  border-right: 0;
  right: -11px;
  bottom: 0px;
}

.datewise-row {
  text-align: center;
  margin: 20px auto;
  font-size: 13px;
  color: #999;
  position: relative;
  z-index: 1;
}

.datewise-row span {
  background: #fff;
  padding: 0 10px;
}

.datewise-row:before {
  content: "";
  background: #f0f0f0;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;
  top: 10px;
}

.chat-box .type-area .input-wrapper input {
  outline: none;
  border: 0;
  padding-left: 28px;
  height: 57px;
  width: 100%;
  font-size: 16px;
  padding-right: 150px;
  border-radius: 0;
  font-size: 17px;
  color: #7c7c7c;
  font-family: "Poppins-Regular";
}

.chat-btn-set {
  position: absolute;
  top: 0;
  right: 0;
}

.type-area {
  padding: 0 0 0px 0;
  position: absolute;
  width: 100%;
  left: 0;
  border-top: 2px solid #e8eded;
  bottom: 0;
}

.chat-box .type-area .button-add {
  display: flex;
  align-items: center;
  position: relative;
}

.chat-box .type-area .button-add i {
  font-size: 30px;
  color: lightgrey;
  cursor: pointer;
}

.chat-box .type-area .button-add i:hover {
  color: #be79df;
}

.chat-box .type-area .button-add .others {
  display: flex;
  padding: 10px 15px;
  background-color: #fff;
  position: absolute;
  top: 5px;
  z-index: -1;
  right: -52px;
  border-radius: 999px;
  box-shadow: 0 0 25px -5px lightgray;
  transition: 0.3s all ease-out;
}

.chat-box .type-area .button-add .others span i {
  font-size: 20px;
  color: #be79df;
}

.chat-box .type-area .button-add .others span.image-button {
  margin: 0 25px;
}

.chat-box .type-area .button-add .others .emoji-button {
  position: relative;
}

.chat-box .type-area .button-add .others .emoji-button .emoji-box {
  display: none;
  position: absolute;
  width: 0px;
  height: 0px;
  top: 0px;
  right: 0px;
  transition: 0.3s all ease-out;
}

.chat-box .type-area .button-add .others .emoji-button .emoji-box span {
  user-select: none;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
  width: 20px;
  height: 20px;
}

.chat-box .type-area .button-send {
  color: #95d1c8;
}

.chat-box .type-area .button-send:hover {
  color: #3cb7f3;
}

.chat-box .type-area .button-send {
  font-size: 15px;
  margin: 0;
  background: #75520a;
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #fff;
  border: 0;
  margin-left: 5px;
}

.chat-box .type-area .plus-icon,
.chat-box .type-area .camera-icon {
  color: #75520a;
  margin: 0 6px;
  font-size: 21px;
}

.chat-btn-set {
  position: absolute;
  top: 10px;
  right: 16px;
  display: flex;
  align-items: center;
}

.card-block {
  background: #fff;
  padding: 31px;
  border-radius: 20px;
  margin: 11px 0;
  box-shadow: 0px 0px 68px 0px rgba(0, 0, 0, 0.03);
}

.notify-left .card-block {
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
}

.notify-right .card-block {
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
}

.condidate_Date_block.validUpToDate {
  color: #c26056;
}

.newThemeBtn.newThemeBtnCancel {
  background: #ce504f;
  border-color: #ce504f;
}

.paymentDetailHead {
  font-size: 24px;
  color: #003661;
  font-family: "Poppins-SemiBold";
}

.paymentTerm {
  color: #003661;
  font-size: 20px;
  font-family: "Poppins-Medium";
  margin-top: 20px;
  flex: 1;
}

.paymentTerm span {
  font-size: 16px;
  color: #617789;
  font-family: "Poppins-Light";
  display: block;
}

.paymentPric {
  font-size: 35px;
  font-family: "Poppins-SemiBold";
  color: #003661;
  text-align: right;
}

.paymentPric span {
  color: #cd4f4f;
  font-size: 18px;
  display: block;
}

.paymentDetailSubHead {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.payment_Radio .maleRadio label.genderLabel {
  font-size: 20px;
}

.payment_Radio .maleRadio label::before {
  top: 6px;
}

.payment_Radio .maleRadio label::after {
  top: 10px;
}

ul.payment_detail_bank {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.payment_detail_bank li {
  font-size: 16px;
  color: #003661;
  font-family: "Poppins-Medium";
  line-height: 35px;
  display: flex;
  align-items: center;
}

ul.payment_detail_bank li span {
  font-size: 16px;
  color: #617789;
  font-family: "Poppins-Light";
  display: inline-block;
  flex: 0 0 200px;
}

.payment_Radio {
  margin: 10px 0;
}

.maleRadio input[type=radio]:checked + label {
  color: #75520a;
}

.upload-img label {
  display: block;
  margin-bottom: 25px;
}

.upload-img label a {
  font-size: 12px;
  background: #75520a;
  color: #fff !important;
  padding: 10px 8px;
  border-radius: 11px;
}

.upload-pdf {
  width: 130px;
  height: 90px;
  line-height: 90px;
  border-radius: 10px;
  text-align: center;
  background: rgba(240, 218, 172, 0.6);
  margin-bottom: 20px;
}
.upload-pdf svg {
  color: #e6c47d;
  width: 40px;
  height: 40px;
}

.profile_box.subBoxPlan {
  padding: 0;
  margin: auto;
  padding-bottom: 20px;
  border-radius: 15px;
  overflow: hidden;
}

.frstMonth .sub_head_month {
  background-image: url(../images/frst_month.png);
}

.thirdMonth .sub_head_month {
  background-image: url(../images/scnd-month.png);
}

.sixtMonth .sub_head_month {
  background-image: url(../images/third-month.png);
}

.lasttMonth .sub_head_month {
  background-image: url(../images/fouth-month.png);
}

.sub_head_month {
  font-size: 26px;
  font-family: "Poppins-Bold";
  text-align: center;
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: column;
  padding: 70px 20px 110px;
}

.sub_head_month span {
  font-size: 17px;
  font-family: "Poppins-Regular";
  display: block;
}

.sub_plan_price {
  font-size: 42px;
  font-family: "Poppins-SemiBold";
  text-align: center;
  border-bottom: 2px solid #000;
  display: inline-block;
  margin: auto;
}

.sub_plan_vat {
  font-size: 16px;
  color: #124067;
  font-family: "Poppins-Medium";
  text-align: center;
  margin-top: 15px;
}

.frstMonth .sub_plan_price {
  color: #01acb3;
  border-color: #01acb3;
}

.thirdMonth .sub_plan_price {
  color: #f24d87;
  border-color: #f24d87;
}

.sixtMonth .sub_plan_price {
  color: #7d08a9;
  border-color: #7d08a9;
}

.lasttMonth .sub_plan_price {
  color: #0b4d82;
  border-color: #0b4d82;
}

.profile_box.subBoxPlan .newThemeBtn {
  font-size: 19px;
  padding: 10px 30px;
  display: block;
  width: 100%;
  font-family: "Poppins-Medium";
}

.frstMonth .newThemeBtn {
  background: #01acb3;
  background: linear-gradient(48deg, #01acb3 0%, #64c1a5 100%);
  border: 0;
}

.thirdMonth .newThemeBtn {
  background: #f24c88;
  background: linear-gradient(48deg, #f24c88 0%, #f5886d 100%);
  border: 0;
}

.sixtMonth .newThemeBtn {
  background: #7507ae;
  background: linear-gradient(48deg, #7507ae 0%, #ad0f8d 100%);
  border: 0;
}

.lasttMonth .newThemeBtn {
  background: #094b80;
  background: linear-gradient(48deg, #094b80 0%, #4383b6 100%);
  border: 0;
}

.sub_plan_amt {
  margin: 30px auto;
  display: block;
  text-align: center;
}

.sub_plan_content {
  padding: 0 20px;
  color: #003661;
  text-align: center;
  font-size: 14px;
  font-family: "Poppins-Regular";
  line-height: 25px;
}

.sub_btn_parent {
  padding: 0 15px;
  margin-top: 40px;
  text-align: center;
}

.newThemeBtn.cancelTheme {
  background: #ce504f;
  border-color: #ce504f;
}

.themeModal {
  z-index: 9999 !important;
}

.modal.themeModal .modal-content {
  border-radius: 10px;
}

.modal.themeModal .modal-header {
  position: relative;
  background: #75520a;
  color: #fff;
  border: 1px solid #75520a;
}

.modal.themeModal .modal-header button.btn-close {
  position: absolute;
  background: #ce504f;
  color: #fff;
  opacity: 1;
  right: -15px;
  top: -15px;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  font-size: 25px;
  line-height: 1;
  outline: 0;
  box-shadow: none;
}

.modal.themeModal .modal-md .modal-body {
  text-align: center;
  font-size: 19px;
  color: #000;
  font-weight: 500;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal.themeModal .modal-lg .modal-body table.table {
  margin-bottom: 0;
  border: 1px solid #dee2e6;
}

.modal.themeModal .modal-lg .modal-body table.table td,
.modal.themeModal .modal-lg .modal-body table.table th {
  vertical-align: middle;
  padding: 7px 15px !important;
}

.modal.themeModal .modal-lg .modal-body table.table tr:nth-child(even) {
  background: #f5f5f5;
}

.modal.themeModal .modal-lg .modal-body table.table tr td:last-child {
  text-align: left !important;
}

.modal.themeModal .modal-lg .modal-body table.table tr td:first-child {
  position: relative;
}

.modal.themeModal .modal-lg .modal-body table.table tr td:first-child::before {
  position: absolute;
  content: ":";
  font-size: 16px;
  font-weight: 600;
  color: #000;
  right: 0;
}

.modal.themeModal .modal-lg .modal-body table.table tr:nth-child(odd) {
  background: #fff;
}

@media (min-width: 1200px) {
  main.dash-main.margin-main .property.side-nav .side-nav-menu a svg {
    width: 23px;
    margin-right: 35px;
    margin-left: 1px;
  }
}
@media (max-width: 991.98px) {
  .upload_compny_profile {
    flex-wrap: wrap;
  }

  .compny_profile_content {
    max-width: unset;
  }

  .custom_upload_img {
    width: 100%;
    margin-top: 15px;
  }

  .searchFlexGroup {
    display: block;
  }

  .searchOrderBtnGroup {
    margin-bottom: 15px;
  }

  .searchFlexGroup .heading_new {
    margin-bottom: 10px;
  }

  .profile_box .newThemeBtn.secondary_newThemeBtn {
    margin-right: 5px;
  }

  .newThemeBtn {
    padding: 8px 15px;
  }

  .borderLineInterest {
    margin-bottom: 30px;
  }

  .borderLineInterest::before {
    display: none;
  }

  .profile_box.subBoxPlan {
    margin-bottom: 25px;
  }

  .sub_head_month {
    background-position: center -35px;
  }
}
@media (max-width: 767.98px) {
  .modal.themeModal .modal-header button.btn-close {
    top: 5px;
    right: 5px;
  }

  .modal.themeModal .modal-lg .modal-body table.table {
    font-size: 14px;
  }

  .modal.themeModal .modal-md .modal-body {
    text-align: center;
    font-size: 18px;
    color: #000;
    font-weight: 500;
    min-height: 100px;
  }

  .paddingSmall {
    padding: 0 !important;
  }

  .codidate_profile_block {
    width: 40px;
    height: 40px;
  }

  .condidate_name_block,
.condidate_Date_block,
.condidate_interest {
    font-size: 16px;
  }

  .condidate_name_block span:not(.tags),
.condidate_Date_block span:not(.tags),
.condidate_interest span:not(.tags) {
    font-size: 13px;
    margin-top: 0;
  }

  .condidate_Table .table th,
.condidate_Table .table td {
    padding: 10px;
  }

  .condidate_btn_group a {
    width: 31px;
    height: 31px;
  }

  .tags {
    padding: 3px 8px;
  }

  .newThemeBtn {
    padding: 8px 16px;
    font-size: 15px;
    min-width: 120px;
  }

  .profile_box {
    padding: 15px;
    border-radius: 15px;
  }

  .heading_new {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .upload_compny_profile {
    padding-left: 0;
    padding-bottom: 15px;
  }

  .compny_profile_img {
    position: unset;
    margin: 0 auto 15px;
  }

  .compny_other_formField {
    margin-top: 15px;
  }

  .form-group,
.dividerHeading {
    margin-bottom: 15px !important;
  }

  .newThemeBtn {
    margin-top: 10px;
  }

  .profile_box label:not(.customLabel) {
    font-size: 14px;
    margin-top: 0;
  }

  .profile_box .form-control {
    font-size: 14px;
  }

  .dividerHeading {
    font-size: 16px;
  }

  .searchFlexGroup .heading_new {
    margin-bottom: 0px;
  }

  .top_search_order {
    display: block;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .top_search_order .condidate_btn_group {
    margin-top: 10px;
  }

  .search_order_img {
    min-width: 45px;
    min-height: 45px;
    width: 45px;
    height: 45px;
  }

  .search_order_img img {
    margin-right: 10px;
  }

  .search_order_content {
    font-size: 16px;
  }

  .search_order_content span {
    font-size: 13px;
  }

  .top_search_body .searchLabel {
    font-size: 14px;
    margin-bottom: 5px;
  }

  ul.search_feature_card .tags {
    margin-top: 5px;
  }

  .top_search_body .search_label_content {
    margin-bottom: 5px;
    font-size: 14px;
  }

  ul.pagination {
    flex-wrap: wrap;
  }

  .match_collapse_head {
    display: block;
  }

  .match_coll_Label {
    margin-bottom: 10px;
  }

  .match_card_body_progress {
    display: block;
  }

  .match_card_body {
    padding: 10px;
  }

  .match_card {
    padding: 10px;
  }

  .top_search_order .condidate_btn_group {
    flex-wrap: wrap;
  }

  .progress-circle {
    margin-top: 10px;
  }

  .progress {
    height: 10px !important;
  }

  .new_progress_value {
    font-size: 14px;
    top: -5px;
  }

  .match_coll_Label {
    font-size: 14px;
  }

  ul.pagination .page-link:not(.nameLink) {
    width: 30px;
    height: 30px;
    line-height: 1;
  }

  .interestImgLogo img {
    max-width: 150px;
  }

  .interestImgLogo {
    margin-bottom: 15px;
  }

  .interestLogoName {
    font-size: 18px;
  }

  .interestLogoAdd {
    margin-bottom: 10px;
    font-size: 13px;
  }

  footer .link nav a {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }

  footer .social nav {
    text-align: center;
  }

  .paymentDetailHead {
    font-size: 17px;
  }

  .paymentDetailSubHead {
    display: block;
  }

  .paymentTerm {
    font-size: 16px;
    margin-top: 10px;
  }

  .paymentTerm span {
    font-size: 13px;
  }

  .paymentPric {
    font-size: 18px;
    text-align: left;
    margin-top: 5px;
  }

  .paymentPric span {
    font-size: 14px;
  }

  .payment_Radio .maleRadio label.genderLabel {
    font-size: 16px;
  }

  .payment_Radio .maleRadio label::before {
    top: 4px;
  }

  .payment_Radio .maleRadio label::after {
    top: 8px;
  }

  ul.payment_detail_bank li {
    display: block;
    font-size: 14px;
    line-height: 30px;
  }

  ul.payment_detail_bank li span {
    width: 100%;
    font-size: 14px;
  }

  .searchFlexGroup {
    margin-bottom: 15px;
  }

  .condidate_Table {
    margin-top: 0;
  }

  .profile_box.subBoxPlan {
    max-width: 340px;
    margin: 0 auto 30px;
  }

  .sub_head_month {
    background-position: center;
  }

  .sub_plan_amt {
    margin: 10px auto;
  }

  .sub_plan_price {
    font-size: 35px;
  }

  .sub_btn_parent {
    margin-top: 25px;
  }

  .sub_plan_vat {
    font-size: 14px;
    margin-top: 10px;
  }

  .profile_box.subBoxPlan .newThemeBtn {
    font-size: 16px;
    padding: 8px 30px;
  }
}
@media (max-width: 574px) {
  body {
    padding-bottom: 0 !important;
  }

  footer {
    margin-bottom: 0;
  }
}
.dash-bx-pad .login-top .login-form .form-field {
  padding-top: 10px;
}

.top-user1 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #e6dbc1;
  /* position: absolute; */
  left: 0;
  top: 0;
}
.top-user1 img {
  width: 100%;
  opacity: 0;
  height: 100%;
}

.form-field .form-check .form-check-label a b {
  color: #8e6a16;
  padding-right: 1px;
}

.active-dot {
  width: 10px;
  height: 10px;
  position: absolute;
  background: #0dd82e;
  border-radius: 50%;
  margin-right: 10px;
  left: -23px;
  bottom: -21px;
}

.active-dot-offline {
  width: 10px;
  height: 10px;
  position: absolute;
  background: #b8b8b8;
  border-radius: 50%;
  margin-right: 10px;
  left: -23px;
  bottom: -21px;
}

.job-titles-all {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  text-align: center;
  width: 100%;
}

.job-titles-all img {
  max-width: 50px;
  max-height: 50px;
  color: #8d6719;
}

.add-more-img {
  height: 100%;
  display: block;
  text-align: center;
  width: 100%;
}

.job-titles-all .img {
  min-height: auto !important;
  color: #8d6719;
}

.messageFilterUsr {
  width: 100%;
}

.messageFilterUsr button {
  width: 100%;
}

ul.messageFilterUsr li {
  width: 50%;
}

ul.messageFilterUsr.nav-tabs .nav-link.active,
ul.messageFilterUsr .nav-tabs .nav-item.show .nav-link {
  color: #8d6719;
  background-color: #fff;
  border: 0 !important;
  border-bottom: 2px solid #8d6719 !important;
}

ul.messageFilterUsr.nav-tabs li button {
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

ul.messageFilterUsr.nav-tabs .nav-link:hover,
ul.messageFilterUsr.nav-tabs .nav-link:focus {
  border-color: rgba(255, 255, 255, 0) rgba(233, 236, 239, 0) rgba(222, 226, 230, 0);
}

.mover svg {
  width: 12px;
  height: 12px;
  color: #fff;
}

.mover {
  background: #003661;
  color: #fff;
  padding: 8px 13px;
  border: #003661 solid 2px !important;
  border: 0;
  font-size: 15px;
  border-radius: 70px;
}

.mover:hover {
  background: #fff;
  border: #003661 solid 2px;
  color: #003661;
  text-decoration: none;
}

.mover a:hover {
  text-decoration: none !important;
}

.active-gray {
  width: 10px;
  height: 10px;
  background: #b8b8b8;
  border-radius: 50%;
  margin-right: 10px;
}

.active-green {
  width: 10px;
  height: 10px;
  background: #0dd82e;
  border-radius: 50%;
  margin-right: 10px;
}

.staturChat1 {
  display: flex;
  align-items: center;
  padding: 0;
}

.error-notfound {
  padding: 30px 20px;
  text-align: center;
}

.filter_block {
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 30px;
  margin-bottom: 40px;
}

.filter_block .form-group {
  margin: 10px 10px !important;
  display: inline-block;
  max-width: 150px;
  width: auto;
  border-radius: 0;
  border: 0;
  width: 100%;
  color: rgba(51, 51, 51, 0.4) solid 1px;
  font-size: 14.42px;
  margin-right: 18px;
  height: 44px;
}

.filter_block .form-group .form-control {
  font-size: 13px;
  border-radius: 5px;
  border: rgba(51, 51, 51, 0.4) solid 1px;
  height: 44px;
}

.filter_block .searchField input {
  font-size: 13px;
  border-radius: 5px;
  max-width: 160px;
  width: 100%;
  border: rgba(51, 51, 51, 0.4) solid 1px;
  height: 44px;
}

.filter_block .form-group .custom-select {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAICAYAAAAm06XyAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDAyIDc5LjE2NDQ2MCwgMjAyMC8wNS8xMi0xNjowNDoxNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjRGQUFBMTlEQjc5MTExRUI4QjdFRDU4OTg5MDg5MTdCIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjRGQUFBMTlFQjc5MTExRUI4QjdFRDU4OTg5MDg5MTdCIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NEZBQUExOUJCNzkxMTFFQjhCN0VENTg5ODkwODkxN0IiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NEZBQUExOUNCNzkxMTFFQjhCN0VENTg5ODkwODkxN0IiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5dggBXAAAAYElEQVR42mJkYGBoA+IsBtLBNBDBCMSLgPg/CXgRVB8YMAPxGiI1roGqRwFsQLyVgMatUHVYARcQ78OhcR9UHi/gA+KTaBpPQsWJAvxAfBmq8TKUTxIQBeJNUBorAAgwAFuML1zqwhPzAAAAAElFTkSuQmCC);
  position: relative;
  background-repeat: no-repeat;
  left: 0;
  background-position: 95% 50%;
  background-size: 11px;
}

.overall_tabs ul {
  justify-content: flex-end;
  border: 0;
}

.overall_tabs ul li button {
  color: #495057;
  background: #e9ecef;
  border: none !important;
}

.overall_tabs ul li {
  margin-right: 10px;
}

.overall_tabs ul li:last-child {
  margin-right: 0;
}

.overall_tabs .nav-tabs .nav-link {
  border: 0 !important;
  border-radius: 10px !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-size: 15px;
  background: #e9ecef !important;
}

.overall_tabs {
  margin: 20px 0;
}

.overall_tabs .nav-tabs .nav-link.active {
  background: #75520a !important;
  color: #fff;
}

.top_search_order.top_search_line {
  margin-bottom: 15px;
}

.theme-table-block {
  width: 100%;
}

.theme-table.theme-table-block {
  border: 1px solid #e0e9e3;
  border-radius: 0px;
  margin-top: 20px;
  background-color: #fff;
  overflow: hidden;
}

.theme-table.theme-table-block .table thead th {
  border: 0;
  padding: 0.75rem !important;
  font-size: 15px;
  font-weight: 500;
}

.theme-table.theme-table-block .table thead th {
  border: 0;
  background-color: white;
  color: #003661;
  font-family: "Poppins-Medium";
}

.theme-table.theme-table-block .table tbody td {
  vertical-align: middle;
  padding: 0.6rem !important;
  font-size: 15px;
  color: #666;
  border: 1px solid #dee2e6;
}

.theme-table.theme-table-block .btn-theme {
  font-size: 13px;
  width: auto;
  padding: 10px 25px;
}

.theme-table.theme-table-block .table tbody td label {
  max-width: 140px;
  font-weight: 600;
  color: #8ea1b1;
  font-size: 14px;
  width: 100%;
  margin: 0;
}

.faq_Headheding h1 {
  color: #8e6a16;
  font-size: 40px;
}

/* Scrollbar Styling */
.themeModal .modal-body::-webkit-scrollbar {
  width: 10px;
}

.themeModal .modal-body::-webkit-scrollbar-track {
  background-color: #ccc;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.themeModal .modal-body::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #999;
}

.reg-head h2 {
  font-size: 21px;
  color: #003661;
  font-weight: 700;
  font-family: "Poppins";
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.cookieButton_cont input {
  display: none;
}

.cookieButton_cont input:checked + #cookieBar {
  bottom: -100%;
}

.cookieButton_cont label {
  display: inline-block;
  padding: 0.25em;
}

.cookieButton_cont label:hover {
  cursor: pointer;
}

#cookieBar {
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  padding: 20px 0px;
  text-align: center;
  background-color: #8d6719;
  background-repeat: no-repeat;
  background-size: contain;
  transition: 1s ease;
  -webkit-transition: 1s ease;
}

#cookieBar P a {
  color: #92ceff;
}

#cookieBar P {
  width: 75%;
  float: left;
  display: inline-block;
  font-size: 14px;
  margin: 0;
  line-height: 24px;
  text-align: LEFT;
  color: #F5F5F5;
  word-break: break-word;
}

#cookieBar button {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 8px 16px;
  text-transform: uppercase;
  font-size: 15px;
  margin-top: 5px;
  margin-right: 10px;
  border-radius: 4px;
}

.interst_section_right {
  flex-grow: 1;
  text-align: right;
}

.interst_section_heading {
  display: flex;
  align-items: center;
}

.mover_arrow {
  display: none;
}

.flex-frow-1 {
  flex-grow: 1;
  text-align: right;
}

.flex-display {
  display: flex;
  align-items: center;
}

.allow_action {
  font-size: 13px;
  font-weight: 500;
}

.allow_action label {
  padding-bottom: 10px;
}

.allow_action button {
  color: #fff;
  background-color: #8d6719;
  border-color: #8d6719;
}

.allow_action button:hover {
  color: #fff;
}

.noti-drop {
  position: relative;
  cursor: pointer;
}
.noti-drop:hover .notiDropdown {
  display: block;
}
.noti-drop .notiDropdown {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  padding: 53px 0 0 0;
  width: 290px;
  display: none;
}
.noti-drop .notiDropdown .notiDropdown_inner {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 9px #00000017;
  border-radius: 5px;
  overflow: hidden;
}
.noti-drop .notiDropdown .notiDropdown_inner ul > li > a {
  display: inline-block;
  width: 100%;
  color: #003661;
  font-family: "Poppins-Regular";
  font-size: 13px;
  text-decoration: none;
  padding: 10px;
}
.noti-drop .notiDropdown .notiDropdown_inner ul > li > a:hover {
  background-color: #f8f8f8;
}
.noti-drop .notiDropdown .notiDropdown_inner ul > li > a span {
  display: block;
  margin-top: 2px;
  font-size: 11px;
  color: #8ea1b1;
}

.viewall_noti {
  width: 100%;
  text-align: center;
  padding: 10px;
  display: block;
  background-color: #75520a;
  color: #fff;
  text-decoration: none;
  font-size: 15px;
}
.viewall_noti:hover {
  opacity: 0.8;
  color: #ffffff;
  text-decoration: none;
}

.notiDropdown_inner > ul {
  max-height: 330px;
  overflow-y: auto;
}

.notiDropdown_inner > ul::-webkit-scrollbar {
  width: 10px;
}

.notiDropdown_inner > ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #999;
}

.notiDropdown_inner > ul::-webkit-scrollbar-track {
  background-color: #ccc;
  border-radius: 10px;
}

.notiDropdown_inner ul > li:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .noti-drop .notiDropdown {
    transform: translateX(40%);
  }
}
.secondary_newThemeBtn_sec {
  margin-right: 15px;
}