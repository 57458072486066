@media (min-width: 1440px) and (max-width: 1600px) {

  .sign-auth-form .login-top .login-form.steps-width,
.sign-auth-form .login-top .login-form {
  padding: 0px 65px 40px 65px !important;
}
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .sign-auth-form .login-side-image {
    position: absolute;
    left: 71px;
    right: 0;
    top: 50%;
    max-width: 540px;
}
.sign-auth-form .login-top .login-form.steps-width,
.sign-auth-form .login-top .login-form {
  padding: 0px 45px 40px 45px !important;
}
.sign-auth-form .login-side-image .image-tag-top .img-tag {
  top: 40%;
  width: 100%;
}
.sign-auth-form .login-side-image .image-tag-top .img-tag h2 {
  font-size: 34px;}
}

@media (min-width: 1200px) and (max-width: 1309px) {}

@media (min-width: 1200px) {}

@media (min-width: 992px) {}

@media (min-width: 768px) {}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .sign-auth-form .login-top .login-form.steps-width,
  .sign-auth-form .login-top .login-form {
    padding: 0px 45px 40px 45px !important;
}
    .terms-content .accordion-button {
        font-size: 18px;}
        .form-steps .radios-and-checks .heading-top h2 {
          font-size: 18px;}
          .radios-and-checks .radios-and-checks-box .heading h2 {
            font-size: 17px;}

        .sign-auth-form .login-side-image .image-tag-top .img-tag {
          top: 40%;
          width: 100%;
      }


        .sign-auth-form .login-side-image{    position: absolute;
          left: 44px;
          right: 0;
          top: 50%;
          max-width: 540px;}
          .sign-auth-form .login-side-image .image-tag-top .img-tag h2 {
            font-size: 27px;}
}

@media (min-width: 768px) and (max-width: 1199.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {
    .login-card .card-content .nav-tabs .nav-link{ font-size: 11px;}
    .login-card .head h3 {
      font-size: 18px;}
      .form-steps .radios-and-checks .heading-top h2 {
        font-size: 19px;}
        .radios-and-checks .radios-and-checks-box .heading h2 {
          font-size: 18px;}
          .notify-users-left {
     
            font-size: 15px;
     
        }
        .login-form.login_campay_reg{ padding-top: 0 !important;}
        .login_campay_reg  .form-field {
          padding-top: 40px;
      }
        .mover {
          background: #003661;
          color: #fff;
          padding: 8px 6px;
          border: #003661 solid 2px !important;
          border: 0;
          font-size: 13px;
          border-radius: 70px;
      }
}


@media (max-width: 991.98px) {
  .heading_new {
    font-size: 20px;}
  .heading h4 {
    font-size: 26px;

}
.note-block {
  padding-bottom: 20px;
  font-size: 15px;
}
.line-heading h4 {
  line-height: 27px;
  font-size: 19px;
}
.sign-auth-form .login-top .login-form{ min-height: auto;}
.login-form.steps-width {
  max-width: 820px !important;
  margin: 0 auto !important;
}
  .searchOrderBtnGroup .searchField {
    display: inline-block;
}
    .sign-auth-form .login-top .login-form.steps-width {
        padding: 0px 51px 40px 50px !important;
    }
    .sign-auth-form .login-top .login-form {

        max-width: 100%;
    }
    .terms-content .accordion-button {    line-height: 27px;
        font-size: 18px;}
  .faq_Headheding h1 {
    font-size: 35px ;
  }
  .sign-auth-form .login-side-image {
    position: absolute;
    left: 41px;
    right: 0;
    top: 50%;
    max-width: 540px;
}
footer{ position: unset;}
.sign-auth-form .login-side-image .image-tag-top .img-tag {
    padding: 20px 15px 30px 15px;
    width: 100%;
}
body{ padding-bottom: 0;}
}

@media (max-width: 767.98px) {
  .login-form.login_campay_reg{ padding: 0 !}
 .login_campay_reg .form-field{ padding-top: 0 !important;}
  .sign-auth-form .login-top .login-form .form-field {
    padding-top: 60px;
}
  .compny_profile_img {
    position: unset;
    margin: 0 0 10px 0;
}
  .mover_arrow{     display: block;
    padding-left: 20px;}
  .arrow-div{ display: inline-flex; align-items: center;}
    .mover_arrow svg{     color: #003661;
        width: 17px;
        height: 17px;
    }
    .form-steps .radios-and-checks .heading-top-step h2 {
      font-size: 18px;
    }  
  .notify-wrapper {
    display: flex !important;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 68px 0px rgb(0 0 0 / 3%);
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.chat_open .notify-left {
    /* width: 100%; */
    z-index: 1;
    flex: unset;
    height: 100%;
    background: #fff;
    border-right: 0;
}
.notify-right {
    flex: 1;
    position: absolute;
    border-left: 0;
    left: -100%;
    width: 100%;
    top: 0;
    background: #fff;
    z-index: 1;
}

.chat_open .notify-right {
    flex: 1;
    position: absolute;
    border-left: 0;
    left: 0%;
    width: 100%;
    top: 0;
    background: #fff;
    z-index: 1;
}
  /* .interst_section_right {
    flex-grow: 1;
    text-align: left;
} */
  .faq_Headheding h1 {
    font-size: 30px;
  }
  .graph .flex-box .img-box {
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;

}
.graph .con p {
  font-size: 15px;

}
.graph .box-con h3 {
  font-size: 25px;

}
.month-dropdown .select-month select {
  font-size: 13px;
}
.job-status-head.flex-box{     flex-wrap: wrap;}
.job-status-head.flex-box .toggles {     flex-wrap: wrap; margin-top: 15px;}
  .radios-and-checks .radios-and-checks-box .custom-control label {
    font-size: 15px;
 
}
  .contact-box .dash-heading {
    font-size: 20px;}
  .terms-content .accordion-button {
    font-size: 18px;
  }
  .form-text{ font-size: 12px;}


  .themeModal h5.modal-title  {
    font-size: 14px;
}
.themeModal .modal-body{    font-size: 14px; }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .form-steps .radios-and-checks .heading-top h2 {
    font-size: 19px;}
    .radios-and-checks .radios-and-checks-box .heading h2 {
      font-size: 18px;}

      .heading_new {
        font-size: 19px;
    }
    .top_search_order {
      display: flex;
      padding-bottom: 15px;
      margin-bottom: 15px;
  }
      .searchField input {
        height: 44px;
        padding: 5px 10px;
        background: #fff;
        border-color: #fff;
        border-style: solid;
        border-radius: 10px;
        box-shadow: 0 0 9px -2px rgb(0 0 0 / 20%);
        font-family: "Poppins-Regular";
        padding-right: 35px;
        box-shadow: none;
        outline: 0;
        max-width: 145px;
        color: #003661;
    }
}

@media (max-width: 575.98px) {
  .sign-auth-form .login-top .login-form.steps-width {
    padding: 0px 30px 40px 30px !important;
}
}




@media (max-width: 1439px) {
  .theme-btn .btn {
    padding: 6px 20px 8px 20px;
    font-size: 13px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .sign-auth-form .form-field .head h4 {
    font-size: 18px;}
  .login-card .card-content .nav-tabs .nav-link {
    font-size: 11px;
  }
  .radios-and-checks .radios-and-checks-box .custom-control label {
    font-size: 15px;
 
}
.notify-users-left {

  font-size: 19px;

}
.line-heading h4 {
  line-height: 27px;
  font-size: 19px;
}
.note-block {
  padding-bottom: 20px;
  font-size: 15px;
}
}

@media (max-width: 767.98px) {
  .terms-content .accordion-button {
    font-size: 17px;
    line-height: 26px;
  }

  .filter_block .form-group {
    max-width: 130px;
  }

  .theme-table-responsive table thead {
    display: none;
  }

  .theme-table {
    border: 0;
    border-radius: 0;
  }

  .theme-table-responsive table tr td:first-child {
    width: 100%;
    text-align: left ;
  }

  .theme-table-responsive table,
  .theme-table-responsive table tr,
  .theme-table-responsive table tbody {
    display: block;
    width: 100%;
  }

  .theme-table-responsive table td {
    display: block;
    font-size: 13px;
    position: relative;
    text-align: right ;
  }

  .theme-table-responsive table tr td:nth-child(1) {
    border-top: 0;
  }

  .theme-table-responsive table tr {
    border: 1px solid rgb(51 51 51 / 20%);
    margin-bottom: 10px;
    border-radius: 6px;
    overflow: hidden;
  }

  .theme-table-responsive table td::before {
    content: attr(data-label);
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    left: 15px;
    top: 12px;
  }

  .theme-table .btn-theme {
    font-size: 12px;
    padding: 8px 20px;
    border-radius: 6px;
  }

  #cookieBar P {
    width: 100%;
    margin-bottom: 10px;
  }

  #cookieBar button {
    float: none;
  }

  .theme-table.theme-table-block .table tbody td {
    border-top: 0;
  }

  .theme-table.theme-table-block {
    border: 0;
  }

  .theme-table.theme-table-block .table tbody td,
  .theme-table.theme-table-block .table tbody td label {
    font-size: 14px;
    border: 0;
  }

}
@media (max-width: 360.98px) {

  .flex-frow-1 {
    text-align: left;
}
  .search_OrderSerch.searchField{ width: 100%; }
  .search_OrderSerch.searchField input{    max-width: 100%;
    width: 100%;}
    .flex-frow-1  .newThemeBtn {
      padding: 8px 12px;
      font-size: 14px; margin: 0;
      min-width: 117px;
  }
  .notify-users-left {

    font-size: 19px;
   
}
.interst_section_right .newThemeBtn { margin-left: 0; }
}


@media (max-width: 575.98px) {
.order_SearchbtnGroup.condidate_btn_group{     padding-left: 50px;}

  .search_OrderSerch.searchField{ width: 100%; }
  .search_OrderSerch.searchField input{    max-width: 100%;
    width: 100%;}
    .flex-frow-1  .newThemeBtn {
      padding: 8px 5px;
    font-size: 14px;
    margin: 0;
    min-width: 117px;
  }
  .notify-users-left {

    font-size: 19px;
   
}

.chat_open .notify-left.chat_arrow {
  width: 100%;

  background: #fff;
 
  z-index: 2; left: 0;}

  .filter_block.form-block .form-group{    margin: 0px !important ; margin-bottom: 10px !important; }
  .sign-auth-form .form-field .head h3 {
    font-size: 19px;}
  .faq_Headheding h1 {
    font-size: 28px;
  }
  .searchField input{     max-width:185px;}
  .interst_section_right {
    flex-grow: 1;
    text-align: left;
}
  .heading_new {
    font-size: 20px;
    margin-bottom: 9px !important;
}
  .login-card .card-content .nav-tabs .nav-link img {
    max-width: 18px;
}
  .dash-heading {
    text-align: center;
  }

  .filter_block .form-group {
    max-width: 100%;
    margin: 0 ;
    margin-bottom: 10px ;
  }

  .filter_block .searchField input {
    max-width: 100%;
    width: 100%;
  }

  .searchField {
    display: block;
  }

  .theme-table.theme-table-block .table tbody td,
  .theme-table.theme-table-block .table tbody td label {
    font-size: 12px;
    border: 0;
  }
}
